import { useEffect } from "react";
import { Form, Row, Col, Button } from "antd";

import useInquiryUpdateById from "hooks/inquiry/useInquiryUpdateById";

import TextInput from "components/Input/TextInput";

import "./styles.scss";

export default ({ itemId, onUpdateSuccess }) => {
  const [form] = Form.useForm();

  const {
    inquiryUpdateByIdTrigger,
    inquiryUpdateByIdLoading,
    inquiryUpdateByIdResult,
  } = useInquiryUpdateById();
  useEffect(() => {
    if (!inquiryUpdateByIdLoading) {
      if (inquiryUpdateByIdResult) {
        form.resetFields();
        onUpdateSuccess();
      }
    }
  }, [inquiryUpdateByIdLoading, inquiryUpdateByIdResult]);

  return (
    <Form
      className="row-col pending-inquiry-quantity-form"
      layout="vertical"
      form={form}
      initialValues={{}}
      onFinish={({ quantity, remarks }) => {
        const variables = {
          id: itemId,
          quantity: quantity,
          status: "ACCEPTED",
        };

        if (remarks) {
          variables.remarks = remarks;
        }

        console.log("variables --> ", variables);

        inquiryUpdateByIdTrigger(variables);
      }}
    >
      <Row gutter={[12, 0]}>
        <Col md={12}>
          <TextInput
            name="quantity"
            placeholder="Quantity"
            rules={[
              {
                required: true,
                message: "Please input quantity!",
              },
            ]}
          />
        </Col>
        <Col md={12}>
          <TextInput name="remarks" placeholder="Remarks" rules={[]} />
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <Form.Item wrapperCol={{ span: 24 }} style={{ marginTop: 10 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={inquiryUpdateByIdLoading}
              block
            >
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
