import useGraphQLQuery from "hooks/common/useGraphQLQuery";

const query = `
  BannerById($id: String!) {
    bannerById(id: $id) {
      _id
      title
      link
      type
      image
      status
    }
  }
`;

export default (id) => {
  const { result, loading, error } = useGraphQLQuery({
    query,
    key: 'bannerById',
    variables: { id },
    fetchPolicy: 'cache-and-network'
  });

  return { bannerByIdResult: result, bannerByIdLoading: loading, bannerByIdError: error };
}