import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  UserUpdateById($id: String!, $displayPicture: String, $email: String!, $firstName: String!, $lastName: String!, $gender: UserGender!, $password: String, $role: UserRole!, $status: Status!) {
    userUpdateById(id: $id, displayPicture: $displayPicture, email: $email, firstName: $firstName, lastName: $lastName, gender: $gender, password: $password, role: $role, status: $status) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'userUpdateById'
  });

  return { userUpdateByIdTrigger: trigger, userUpdateByIdResult: result, userUpdateByIdLoading: loading, userUpdateByIdError: error };
}