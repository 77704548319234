import { useEffect } from 'react';
import { DeleteOutlined } from '@ant-design/icons';

import useInquiryUpdateById from 'hooks/inquiry/useInquiryUpdateById';

import Loader from 'components/Loader';

export default ({ itemId, onUpdateSuccess }) => {
  const { inquiryUpdateByIdTrigger, inquiryUpdateByIdLoading, inquiryUpdateByIdResult } = useInquiryUpdateById();
  useEffect(() => {
    if (!inquiryUpdateByIdLoading) {
      if (inquiryUpdateByIdResult) {
        onUpdateSuccess();
      }
    }
  }, [inquiryUpdateByIdLoading, inquiryUpdateByIdResult]);

  return (
    <>
      {
        inquiryUpdateByIdLoading ?
          <Loader />
          :
          <DeleteOutlined style={{ color: '#fb1c1c', fontSize: '20px' }} onClick={() => inquiryUpdateByIdTrigger({ id: itemId, status: 'CANCELED' })} />
      }
    </>
  );
}