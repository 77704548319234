import useGraphQLLazyQuery from 'hooks/common/useGraphQLLazyQuery';

const query = `
  NotFeaturedProductsByCategory($category: String!) {
    notFeaturedProductsByCategory(category: $category) {
      _id
      title
      slug
      sku
      image
      status
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLLazyQuery({
    query,
    key: 'notFeaturedProductsByCategory',
    fetchPolicy: 'cache-and-network'
  });

  return { notFeaturedProductsByCategoryTrigger: trigger, notFeaturedProductsByCategoryResult: result, notFeaturedProductsByCategoryLoading: loading, notFeaturedProductsByCategoryError: error }
}