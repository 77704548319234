import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  BrandCreate($title: String!, $image: String!, $status: Status!) {
    brandCreate(title: $title, image: $image, status: $status) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'brandCreate'
  });

  return { brandCreateTrigger: trigger, brandCreateResult: result, brandCreateLoading: loading, brandCreateError: error }
}