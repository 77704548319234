import useGraphQLLazyQuery from 'hooks/common/useGraphQLLazyQuery';

const query = `
  Products {
    products {
      _id
      category {
        _id
        title
      }
      brand {
        _id
        title
      }
      title
      slug
      sku
      price
      offerPrice
      deliveryTime
      description
      overview
      image
      video
      status
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLLazyQuery({
    query,
    key: 'products',
    fetchPolicy: 'network-only'
  });

  return { productsTrigger: trigger, productsResult: result, productsLoading: loading, productsError: error }
}