import { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Alert } from "antd";

import useCategoryById from 'hooks/category/useCategoryById';
import useCategoryUpdateById from 'hooks/category/useCategoryUpdateById';

import { routeConfig } from 'Routes/config';

import config from 'configs/config';

import { fileUpload } from 'services/file';

import ImageUpload from "components/Input/ImageUpload";
import TextInput from "components/Input/TextInput";
import SelectInput from "components/Input/SelectInput";
import Loader from 'components/Loader';

import './styles.scss';

export default ({ history, match }) => {
  const urlId = match.params.id;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { categoryByIdResult, categoryByIdLoading } = useCategoryById(urlId);
  useEffect(() => {
    if (!categoryByIdLoading) {
      if (categoryByIdResult) {
        form.setFieldsValue({ image: categoryByIdResult.image, title: categoryByIdResult.title, status: categoryByIdResult.status });
      }
    }
  }, [categoryByIdLoading, categoryByIdResult]);

  const { categoryUpdateByIdTrigger, categoryUpdateByIdResult, categoryUpdateByIdLoading, categoryUpdateByIdError } = useCategoryUpdateById();
  const submitFormData = (formData) => {
    formData.id = urlId;

    setError('');
    setLoading(true);
    if (formData.image instanceof File) {
      fileUpload('category', formData.image).then(res => {
        formData.image = res.data;

        categoryUpdateByIdTrigger(formData);
      });
    } else {
      categoryUpdateByIdTrigger(formData);
    }
  };

  useEffect(() => {
    if (!categoryUpdateByIdLoading) {
      if (categoryUpdateByIdError) {
        const errorMessage = categoryUpdateByIdError.message || 'Something went wrong.'
        setError(errorMessage);
        setLoading(false);
      }

      if (categoryUpdateByIdResult) {
        setLoading(false);
        history.push(routeConfig.category.path);
      }
    }
  }, [categoryUpdateByIdLoading, categoryUpdateByIdResult]);

  return (
    <Card
      bordered={false}
      className="criclebox tablespace"
      title="Update"
    >
      <div className="categoryupdate">
        {categoryByIdLoading ?
          <Loader />
          : null}
        <Form
          className="row-col"
          layout="vertical"
          form={form}
          initialValues={{}}
          onFinish={submitFormData}
        >
          <Row gutter={[24, 0]}>
            <Col md={24}>
              <ImageUpload
                form={form}
                label='Image'
                name='image'
                initialPreview={categoryByIdResult && categoryByIdResult.image ? `${config.assetURL}/${categoryByIdResult.image}` : ''}
                rules={[
                  {
                    required: true,
                    message: "Please input image!",
                  }
                ]}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Title'
                name='title'
                placeholder='Title'
                rules={[
                  {
                    required: true,
                    message: "Please input title!",
                  }
                ]}
              />
            </Col>
            <Col md={24}>
              <SelectInput
                label='Status'
                name='status'
                placeholder='Status'
                rules={[
                  {
                    required: true,
                    message: "Please input status!",
                  }
                ]}
                options={[
                  { title: 'Active', value: 'ACTIVE' },
                  { title: 'In-Active', value: 'INACTIVE' }
                ]}
              />
            </Col>
            {error != '' ?
              <Col md={24}>
                <Alert message={error} type='error' style={{ marginBottom: 24 }} />
              </Col>
              :
              null}
            <Col md={12}>
              <Form.Item wrapperCol={{ offset: 18, span: 6 }}>
                <Button type="primary" htmlType="submit" loading={loading} disabled={categoryByIdLoading} block>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Card>
  );
}
