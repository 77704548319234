import useGraphQLQuery from 'hooks/common/useGraphQLQuery';

const query = `
  Categories {
    categories {
      _id
      title
      slug
      image
      status
    }
  }
`;

export default () => {
  const { result, loading, error } = useGraphQLQuery({
    query,
    key: 'categories',
    fetchPolicy: 'cache-and-network'
  });

  return { categoriesResult: result, categoriesLoading: loading, categoriesError: error }
}