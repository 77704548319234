import { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Table, Typography, Tag, Pagination, Button } from "antd";

import useContactMessagesWithPagination from 'hooks/contactMessage/useContactMessagesWithPagination';

import { routeConfig } from 'Routes/config';

import { extractValueFromQueryString } from 'utils/util'

import Loader from 'components/Loader';
// import TextInput from 'components/Input/TextInput';
import SelectInput from 'components/Input/SelectInput';
import UpdateStatusForm from "./UpdateStatusForm";

import './styles.scss';

export default ({ history, location }) => {
  const status = extractValueFromQueryString(location.search, 'status')
  if (!status) {
    history.push(`${routeConfig.contactMessage.path}?status=pending`)
  }

  let page = extractValueFromQueryString(location.search, 'page')
  if (!page) {
    page = 1
  }
  const limit = 10

  const [form] = Form.useForm();

  const [filterParams, setFilterParams] = useState({});

  const { contactMessagesWithPaginationTrigger, contactMessagesWithPaginationResult, contactMessagesWithPaginationLoading } = useContactMessagesWithPagination();

  useEffect(() => {
    let params = {}

    if (location.search) {
      const queryStringObject = extractValueFromQueryString(location.search)

      if (queryStringObject) {
        form.setFieldsValue(queryStringObject)
        setFilterParams(queryStringObject)

        Object.keys(queryStringObject).forEach(qso => {
          params[qso] = (qso === 'status') ? queryStringObject[qso].toUpperCase() : queryStringObject[qso]
        })
      }
    }

    //pagination update
    params.page = parseInt(page, 10)
    params.limit = limit

    contactMessagesWithPaginationTrigger(params);
  }, [page, location.search]);

  const applyFilter = () => {
    const filterParamsArray = Object.keys(filterParams).map(fp => `${fp}=${fp === 'page' ? '' : filterParams[fp]}`)

    if (filterParamsArray.length) {

      history.push(`?${filterParamsArray.join('&')}`)
    }
  }

  const clearFilter = () => {
    const filterParamsArray = Object.keys(filterParams).map(fp => `${fp}=${fp === 'status' ? filterParams[fp] : ''}`)

    if (filterParamsArray.length) {

      history.push(`${routeConfig.contactMessage.path}?${filterParamsArray.join('&')}`)
    }
  }

  const changePage = (page) => {
    let filterParams = {};

    const queryStringObject = extractValueFromQueryString(location.search)

    if (queryStringObject && Object.keys(queryStringObject).length) {
      Object.keys(queryStringObject).forEach(fpo => {
        if (fpo !== 'page' && fpo !== 'limit') {
          filterParams[fpo] = queryStringObject[fpo]
        }
      })
    }

    const filterParamsArray = Object.keys(filterParams).map(fp => `${fp}=${Array.isArray(filterParams[fp]) ? filterParams[fp].join(',') : filterParams[fp]}`)

    if (filterParamsArray.length) {

      history.push(`?${filterParamsArray.join('&')}&page=${page}`)
    } else {
      history.push(`?page=${page}`)
    }

  }

  let contactMessageColumns = [
    {
      title: "S No",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) => <Typography.Title level={5} style={{ maxWidth: '250px', whiteSpace: 'normal' }}>{index + 1}</Typography.Title>
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value) =>
        <div className="avatar-info">
          <Typography.Title level={5}>{value}</Typography.Title>
        </div>
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (value) =>
        <div className="avatar-info">
          <Typography.Title level={5}>{value}</Typography.Title>
        </div>
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (value) =>
        <div className="avatar-info">
          <Typography.Title level={5}>{value}</Typography.Title>
        </div>
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (value) => <Tag color={value === 'PENDING' ? 'blue' : value === 'ACCEPTED' ? 'green' : 'red'} > {value.toLowerCase()}</Tag >
    }
  ];

  if (status === 'pending') {
    contactMessageColumns = [
      ...contactMessageColumns,
      {
        title: "Action",
        key: "action",
        dataIndex: "action",
        render: (_, record) => record.status === 'PENDING' ? <UpdateStatusForm itemId={record._id} onUpdateSuccess={() => history.push(`${routeConfig.contactMessage.path}`)} /> : null
      }
    ]
  } else {
    contactMessageColumns = [
      ...contactMessageColumns,
      // {
      //   title: "Created By",
      //   key: "createdBy",
      //   dataIndex: "createdBy",
      //   render: (value) =>
      //     <div className="avatar-info">
      //       <Typography.Title level={5}>{value?.email}</Typography.Title>
      //     </div>
      // },
      {
        title: "Updated By",
        key: "updatedBy",
        dataIndex: "updatedBy",
        render: (value) =>
          <div className="avatar-info">
            <Typography.Title level={5}>{value?.email}</Typography.Title>
          </div>
      }
    ]
  }

  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      title="Contact Messages"
    >
      <div className="contactmessage">
        <div className='contactmessage_options'>
          <Row gutter={[16, 16]}>
            <Col md={4}>
              <Form
                className="row-col"
                layout="inline"
                form={form}
                initialValues={{}}
                onValuesChange={(values) => setFilterParams({ ...filterParams, ...values })}
              >
                <Row gutter={[16, 16]} style={{ width: '100%' }}>
                  {/* <Col span={12}>
                    <TextInput
                      name='inquirynumber'
                      placeholder='Inq No'
                      rules={[]}
                    />
                  </Col> */}
                  <Col md={24}>
                    <SelectInput
                      name='status'
                      placeholder='Inquiry Status'
                      rules={[]}
                      options={[
                        { title: 'Pending', value: 'pending' },
                        { title: 'Accepted', value: 'accepted' }
                      ]}
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col md={3}>
              <Button type="primary" className="tag-default" onClick={applyFilter} block>
                Search
              </Button>
            </Col>
            <Col md={3}>
              <Button type="default" className="tag-default" onClick={clearFilter} block>
                Clear
              </Button>
            </Col>
          </Row>
        </div>
        {contactMessagesWithPaginationLoading ?
          <Loader />
          :
          null}
        <div className="table-responsive">
          <Table
            columns={contactMessageColumns}
            dataSource={contactMessagesWithPaginationLoading ? [] : contactMessagesWithPaginationResult?.data}
            rowKey='mobile'
            pagination={false}
            className="ant-border-space"
          />
          {contactMessagesWithPaginationResult ?
            <div className='table-pagination'>
              <Pagination
                current={parseInt(page)}
                pageSize={limit}
                total={contactMessagesWithPaginationResult.total}
                showSizeChanger={false}
                onChange={page => changePage(page)}
              />
            </div>
            :
            null
          }
        </div>
      </div>
    </Card >
  );
}
