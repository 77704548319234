import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Table, Avatar, Typography, Tag, Pagination, Row, Col, Form, Button } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import useCategories from 'hooks/category/useCategories';
import useProductsWithPagination from 'hooks/product/useProductsWithPagination';
import useProductDeleteById from 'hooks/product/useProductDeleteById';

import config from "configs/config";

import { routeConfig } from 'Routes/config';

import { extractValueFromQueryString } from 'utils/util'

import TextInput from 'components/Input/TextInput';
import SelectInput from 'components/Input/SelectInput';
import Loader from 'components/Loader';
import ExcelOptions from './ExcelOptions';

import './styles.scss';

export default ({ history, location }) => {
  let page = extractValueFromQueryString(location.search, 'page')
  if (!page) {
    page = 1
  }
  const limit = 10

  const [form] = Form.useForm();

  const [filterParams, setFilterParams] = useState({});

  const { categoriesResult, categoriesLoading } = useCategories();
  const formatCategoryOptions = () => {
    let formattedCategories = [];
    if (!categoriesLoading && categoriesResult) {
      formattedCategories = categoriesResult.map(c => ({ title: c.title, value: c._id }));
    }

    return formattedCategories;
  }

  const { productsWithPaginationTrigger, productsWithPaginationResult, productsWithPaginationLoading } = useProductsWithPagination();

  useEffect(() => {
    let params = {}

    if (location.search) {
      const queryStringObject = extractValueFromQueryString(location.search)

      if (queryStringObject) {
        form.setFieldsValue(queryStringObject)
        setFilterParams(queryStringObject)

        Object.keys(queryStringObject).forEach(qso => {
          params[qso] = queryStringObject[qso]
        })
      }
    }

    //pagination update
    params.page = parseInt(page, 10)
    params.limit = limit

    productsWithPaginationTrigger(params);
  }, [page, location.search]);

  const applyFilter = () => {
    const filterParamsArray = Object.keys(filterParams).map(fp => `${fp}=${fp === 'page' ? '' : filterParams[fp]}`)

    if (filterParamsArray.length) {

      history.push(`?${filterParamsArray.join('&')}`)
    }
  }

  const clearFilter = () => {
    const filterParamsArray = Object.keys(filterParams).map(fp => `${fp}=`)

    if (filterParamsArray.length) {

      history.push(`${routeConfig.product.path}?${filterParamsArray.join('&')}`)
    }
  }

  const changePage = (page) => {
    let filterParams = {};

    const queryStringObject = extractValueFromQueryString(location.search)

    if (queryStringObject && Object.keys(queryStringObject).length) {
      Object.keys(queryStringObject).forEach(fpo => {
        if (fpo !== 'page' && fpo !== 'limit') {
          filterParams[fpo] = queryStringObject[fpo]
        }
      })
    }

    const filterParamsArray = Object.keys(filterParams).map(fp => `${fp}=${Array.isArray(filterParams[fp]) ? filterParams[fp].join(',') : filterParams[fp]}`)

    if (filterParamsArray.length) {
      history.push(`?${filterParamsArray.join('&')}&page=${page}`)
    } else {
      history.push(`?page=${page}`)
    }

  }

  const { productDeleteByIdTrigger, productDeleteByIdLoading } = useProductDeleteById();

  const columns = [
    {
      title: "S No",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) => <Typography.Title level={5} style={{ maxWidth: '250px', whiteSpace: 'normal' }}>{((parseInt(page) - 1) * 10) + index + 1}</Typography.Title>
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, record) => <div style={{ minWidth: '300px' }}>
        <Avatar.Group>
          <Avatar
            className="shape-avatar"
            shape="square"
            size={40}
            src={`${config.assetURL}/${record.image[0]}`}
          ></Avatar>
          <div className="avatar-info" style={{ maxWidth: '200px', }}>
            <Typography.Title level={5} style={{ whiteSpace: 'normal' }}>{record.title}</Typography.Title>
          </div>
        </Avatar.Group>
      </div>
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      render: (value) => <Typography.Title level={5} style={{ maxWidth: '250px', whiteSpace: 'normal' }}>{value}</Typography.Title>
    },
    {
      title: "Sku",
      dataIndex: "sku",
      key: "sku",
      render: (value) => <Typography.Title level={5}>{value}</Typography.Title>
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (value) => <Typography.Title level={5}>{value?.title}</Typography.Title>
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (value) => <Typography.Title level={5}>{value?.title}</Typography.Title>
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (value) => <Tag color={value.toLowerCase() === 'active' ? 'green' : 'red'} >{value.toLowerCase()}</Tag>
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (_, record) => <><Link to={routeConfig.productUpdate.path.replace(':id', record._id)}><EditOutlined style={{ color: '#1890ff', marginRight: '5px' }} /></Link> <DeleteOutlined style={{ color: '#fb1c1c' }} onClick={() => productDeleteByIdTrigger({ id: record._id })} /></>
    }
  ];

  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      title="Products"
      extra={
        <Link to={routeConfig.productCreate.path}>
          <Button type="primary" className="tag-primary">
            Create
          </Button>
        </Link>
      }
    >
      <div className="product">
        <div className='product_options'>
          <Row gutter={[16, 16]}>
            <Col md={12}>
              <Form
                className="row-col"
                layout="inline"
                form={form}
                initialValues={{}}
                onValuesChange={(values) => setFilterParams({ ...filterParams, ...values })}
              >
                <Row gutter={[16, 16]} style={{ width: '100%' }}>
                  <Col span={8}>
                    <TextInput
                      name='search'
                      placeholder='Search'
                      rules={[]}
                    />
                  </Col>
                  <Col span={8}>
                    <TextInput
                      name='sku'
                      placeholder='Sku'
                      rules={[]}
                    />
                  </Col>
                  <Col span={8}>
                    <SelectInput
                      name='category'
                      placeholder='Category'
                      rules={[]}
                      options={formatCategoryOptions()}
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col md={3}>
              <Button type="primary" className="tag-default" onClick={applyFilter} block>
                Search
              </Button>
            </Col>
            <Col md={3}>
              <Button type="default" className="tag-default" onClick={clearFilter} block>
                Clear
              </Button>
            </Col>
            <Col md={4} offset={2}>
              <ExcelOptions />
            </Col>
          </Row>
        </div>
        {productsWithPaginationLoading || productDeleteByIdLoading ?
          <Loader />
          : null}
        <div className="table-responsive">
          <Table
            columns={columns}
            dataSource={productsWithPaginationResult?.data}
            rowKey="title"
            pagination={false}
            className="ant-border-space"
          />
          {productsWithPaginationResult ?
            <div className='table-pagination'>
              <Pagination
                current={parseInt(page)}
                pageSize={limit}
                total={productsWithPaginationResult.total}
                showSizeChanger={false}
                onChange={page => changePage(page)}
              />
            </div>
            :
            null
          }
        </div>
      </div>
    </Card >
  );
}
