import { useContext } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { UserContext } from 'context/UserContext';

import { getRoutes, routeConfig } from 'Routes/config';

import AuthRoute from 'Routes/AuthRoute';
import PrivateRoute from 'Routes/PrivateRoute';

const ROUTE_TYPES = {
  'auth': (path, Component) => <AuthRoute key={path} exact path={path} component={Component} />,
  'private': (path, Component) => <PrivateRoute key={path} exact path={path} component={Component} />,
  'default': (path, Component) => <Route key={path} exact path={path} component={Component} />
}

export default ({ }) => {
  const { role } = useContext(UserContext);

  const routes = getRoutes({});

  const getInitialRoutePath = () => {
    const [initialRoute] = Object.values(routes).filter((_, i) => i == 0);

    if (initialRoute) {
      return initialRoute.path;
    } else {
      return routeConfig.logout.path;
    }
  }

  return (
    <BrowserRouter>
      <Switch>
        {Object.values(routes).map(value => ROUTE_TYPES[value.type](value.path, value.component))}

        {/* redirect to initial route if route is not found */}
        <Route
          path='*'
          exact
          render={props => <Redirect push to={{ pathname: getInitialRoutePath(), search: props.location.search, state: { from: props.location } }} />}
        />
      </Switch>
    </BrowserRouter>
  );
}