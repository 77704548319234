import { Link, NavLink } from "react-router-dom";
import { Menu } from "antd";

import { routeConfig, getRoutes } from 'Routes/config';

import logo from "assets/images/logo.png";

export default ({ pathname }) => {
  const currentPage = pathname.split('/')[1];

  const sidebarRoutes = getRoutes({ sidebar: true });

  return (
    <>
      <div className="brand">
        <Link to={routeConfig.dashboard.path}>
          <img src={logo} />
          <span>Dashboard</span>
        </Link>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {Object.keys(sidebarRoutes).map(key =>
          <Menu.Item key={key}>
            <NavLink to={sidebarRoutes[key].path}>
              <span
                className="icon"
                style={{
                  background: currentPage === sidebarRoutes[key].path.split('/')[1] ? "#1890ff" : "",
                }}
              >
                {sidebarRoutes[key].sidebar.icon}
              </span>
              <span className="label">{sidebarRoutes[key].sidebar.title}</span>
            </NavLink>
          </Menu.Item>
        )}
      </Menu>
    </>
  );
}
