import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  BannerCreate($title: String!, $link: String, $type: BannerType!, $image: String!, $status: Status!) {
    bannerCreate(title: $title, link: $link, type: $type, image: $image, status: $status) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'bannerCreate'
  });

  return { bannerCreateTrigger: trigger, bannerCreateResult: result, bannerCreateLoading: loading, bannerCreateError: error }
}