import useGraphQLLazyQuery from 'hooks/common/useGraphQLLazyQuery';

const query = `
  UsersWithPagination($page: Int!, $limit: Int!, $email: String) {
    usersWithPagination(page: $page, limit: $limit, email: $email) {
      page
      limit
      total
      data {
        _id
        email
        firstName
        lastName
        gender
        displayPicture
        role
        status
      }
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLLazyQuery({
    query,
    key: 'usersWithPagination',
    fetchPolicy: 'cache-and-network'
  });

  return { usersWithPaginationTrigger: trigger, usersWithPaginationResult: result, usersWithPaginationLoading: loading, usersWithPaginationError: error }
}