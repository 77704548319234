import useGraphQLLazyQuery from 'hooks/common/useGraphQLLazyQuery';

const query = `
  FeaturedProductsByCategory($category: String!) {
    featuredProductsByCategory(category: $category) {
      _id
      title
      slug
      sku
      image
      status
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLLazyQuery({
    query,
    key: 'featuredProductsByCategory',
    fetchPolicy: 'cache-and-network'
  });

  return { featuredProductsByCategoryTrigger: trigger, featuredProductsByCategoryResult: result, featuredProductsByCategoryLoading: loading, featuredProductsByCategoryError: error }
}