import useGraphQLQuery from "hooks/common/useGraphQLQuery";

const query = `
  BrandById($id: String!) {
    brandById(id: $id) {
      _id
      title
      image
      status
    }
  }
`;

export default (id) => {
  const { result, loading, error } = useGraphQLQuery({
    query,
    key: 'brandById',
    variables: { id },
    fetchPolicy: 'cache-and-network'
  });

  return { brandByIdResult: result, brandByIdLoading: loading, brandByIdError: error };
}