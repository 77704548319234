import useGraphQLQuery from 'hooks/common/useGraphQLQuery';

const query = `
  Analytics {
    analytics {
      totalCategories
      totalProducts
      totalInquiries
      pendingInquiries
      acceptedInquiries
      canceledInquiries
    }
  }
`;

export default () => {
  const { result, loading, error } = useGraphQLQuery({
    query,
    key: 'analytics',
    fetchPolicy: 'cache-and-network'
  });

  return { analyticsResult: result, analyticsLoading: loading, analyticsError: error }
}