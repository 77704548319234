import { Form, Switch } from 'antd';

export default ({ form, label, name, defaultChecked, rules }) => {
  return (
    <Form.Item
      className="username"
      name={name}
      rules={rules}
    >
      <Switch defaultChecked={defaultChecked} onChange={(value) => form.setFieldsValue({ [name]: value })} />
      {label}
    </Form.Item>
  );
}