import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  CategoryUpdateById($id: String!, $title: String!, $image: String!, $status: Status!) {
    categoryUpdateById(id: $id, title: $title, image: $image, status: $status) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'categoryUpdateById'
  });

  return { categoryUpdateByIdTrigger: trigger, categoryUpdateByIdResult: result, categoryUpdateByIdLoading: loading, categoryUpdateByIdError: error };
}