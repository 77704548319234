import { useRef, useEffect } from 'react';
import { Row, Col, Form } from 'antd';
import * as XLSX from 'xlsx';

import useProducts from 'hooks/product/useProducts';
import useBulkProductUpdate from 'hooks/product/useBulkProductUpdate';

import SelectInput from 'components/Input/SelectInput';
import Loader from 'components/Loader';

export default ({ }) => {
  const [form] = Form.useForm();

  let excelFileInputRef = useRef()

  const { productsTrigger, productsResult, productsLoading } = useProducts();

  const handleExcelSelectChange = (value) => {
    if (value === 'DOWNLOAD') {
      productsTrigger()
    } else if (value === 'UPLOAD') {
      if (excelFileInputRef && excelFileInputRef.current) {
        excelFileInputRef.current.click()
      }
    }
  }

  useEffect(() => {
    if (!productsLoading && productsResult) {
      const rows = productsResult.map(pr => ({
        'Sku': pr.sku,
        'Title': pr.title,
        'Price': pr.price,
        'Offer Price': pr.offerPrice,
        'Delivery Time': pr.deliveryTime,
        'Category': pr.category ? pr.category.title : null,
        'Brand': pr.brand ? pr.brand.title : null,
        'Description': pr.description,
        'Overview': pr.overview,
        'Video': pr.video,
        'Status': pr.status
      }))

      const worksheet = XLSX.utils.json_to_sheet(rows);
      worksheet['!cols'] = rows.length ? Object.keys(rows[0]).map(_ => ({ wch: 15 })) : []

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

      XLSX.writeFile(workbook, "Products.xlsx");

      form.setFieldsValue({ excel: "EXCEL" })
    }
  }, [productsLoading, productsResult])

  const { bulkProductUpdateTrigger, bulkProductUpdateResult, bulkProductUpdateLoading } = useBulkProductUpdate()

  const handleExcelFileUpload = (event) => {
    const files = event.target.files;

    const reader = new FileReader();
    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result)

      const worksheet = workbook.Sheets[workbook.SheetNames[0]]

      const jsonData = XLSX.utils.sheet_to_json(worksheet)
      const formattedJsonData = jsonData.map(jd => ({
        sku: jd['Sku'],
        title: jd['Title'],
        price: jd['Price'],
        offerPrice: jd['Offer Price'],
        deliveryTime: jd['Delivery Time'],
        category: jd['Category'],
        brand: jd['Brand'],
        description: jd['Description'],
        overview: jd['Overview'],
        video: jd['Video'],
        status: jd['Status']
      }))

      bulkProductUpdateTrigger({ products: formattedJsonData })

      if (excelFileInputRef && excelFileInputRef.current) {
        excelFileInputRef.current.value = '';
      }

      form.setFieldsValue({ excel: "EXCEL" })
    }
    reader.readAsArrayBuffer(files[0])
  }

  if (productsLoading || bulkProductUpdateLoading) {
    return (
      <Loader />
    )
  }

  return (
    <Form
      className="row-col"
      layout="inline"
      form={form}
      initialValues={{ excel: 'EXCEL' }}
      onValuesChange={({ excel }) => handleExcelSelectChange(excel)}
    >
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <input type='file' ref={excelFileInputRef} onChange={handleExcelFileUpload} accept='.xlsx' hidden />
          <SelectInput
            name='excel'
            placeholder='Excel'
            rules={[]}
            options={[
              { title: 'Download', value: 'DOWNLOAD' },
              { title: 'Upload', value: 'UPLOAD' }
            ]}
          />
        </Col>
      </Row>
    </Form>
  )
}