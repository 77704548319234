import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  CategoryDeleteById($id: String!) {
    categoryDeleteById(id: $id) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'categoryDeleteById'
  });

  return { categoryDeleteByIdTrigger: trigger, categoryDeleteByIdResult: result, categoryDeleteByIdLoading: loading, categoryDeleteByIdError: error };
}