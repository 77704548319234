import { useEffect } from 'react';
import { CheckOutlined } from '@ant-design/icons';

import useContactMessageUpdateById from 'hooks/contactMessage/useContactMessageUpdateById';

import Loader from 'components/Loader';

export default ({ itemId, onUpdateSuccess }) => {
  const { contactMessageUpdateByIdTrigger, contactMessageUpdateByIdLoading, contactMessageUpdateByIdResult } = useContactMessageUpdateById();
  useEffect(() => {
    if (!contactMessageUpdateByIdLoading) {
      if (contactMessageUpdateByIdResult) {
        onUpdateSuccess();
      }
    }
  }, [contactMessageUpdateByIdLoading, contactMessageUpdateByIdResult]);

  return (
    <>
      {
        contactMessageUpdateByIdLoading ?
          <Loader />
          :
          <CheckOutlined style={{ color: '#1890ff', fontSize: '20px' }} onClick={() => contactMessageUpdateByIdTrigger({ id: itemId, status: 'ACCEPTED' })} />
      }
    </>
  );
}