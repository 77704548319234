import { Card, Col, Row, Typography } from "antd";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

import useAnalytics from "hooks/analytics/useAnalytics";

import Loader from "components/Loader";

import './styles.scss';

export default ({ }) => {
  const { analyticsResult, analyticsLoading } = useAnalytics();

  const cart = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C7.79086 2 6 3.79086 6 6V7H5C4.49046 7 4.06239 7.38314 4.00612 7.88957L3.00612 16.8896C2.97471 17.1723 3.06518 17.455 3.25488 17.6669C3.44458 17.8789 3.71556 18 4 18H16C16.2844 18 16.5554 17.8789 16.7451 17.6669C16.9348 17.455 17.0253 17.1723 16.9939 16.8896L15.9939 7.88957C15.9376 7.38314 15.5096 7 15 7H14V6C14 3.79086 12.2091 2 10 2ZM12 7V6C12 4.89543 11.1046 4 10 4C8.89543 4 8 4.89543 8 6V7H12ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10ZM13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9Z"
        fill="#fff"
      ></path>
    </svg>,
  ];

  const analytics = {
    totalCategories: {
      title: 'Total Categories',
      icon: cart
    },
    totalProducts: {
      title: 'Total Products',
      icon: cart
    },
    totalInquiries: {
      title: 'Total Inquiries',
      icon: cart
    }
  };

  const analyticsChart = {
    pendingInquiries: {
      name: 'Pending Inquiries',
      color: '#1890ff'
    },
    acceptedInquiries: {
      name: 'Accepted Inquiries',
      color: '#34a234'
    },
    canceledInquiries: {
      name: 'Canceled Inquiries',
      color: '#fb1c1c'
    }
  };

  return (
    <div className="layout-content analytics">
      {analyticsLoading ?
        <Loader />
        :
        null}
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        {analyticsResult ?
          Object.keys(analytics).map((a, i) =>
            <Col
              key={i}
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{analytics[a]['title']}</span>
                      <Typography.Title level={3}>
                        {analyticsResult[a]}
                      </Typography.Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">{analytics[a]['icon']}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          )
          : null}
      </Row>
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        <Col md={20}>
          {analyticsResult ?
            <div className="analytics_piechart">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={400} height={400}>
                  <Pie
                    data={Object.keys(analyticsChart).map(ac => ({ name: analyticsChart[ac]['name'], value: parseInt(analyticsResult[ac]) }))}
                    labelLine={false}
                    label={({ name, value }) => value > 0 ? `${name}-${value}` : null}
                    outerRadius={200}
                    dataKey="value"
                  >
                    {Object.keys(analyticsChart).map((ac, i) => (
                      <Cell key={`cell-${i}`} fill={analyticsChart[ac]['color']} />
                    ))}
                  </Pie>
                  <Legend align="right" verticalAlign="middle" layout="vertical" />
                </PieChart>
              </ResponsiveContainer>
            </div>
            : null}
        </Col>
      </Row>
    </div>
  );
}
