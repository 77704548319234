import useGraphQLLazyQuery from 'hooks/common/useGraphQLLazyQuery';

const query = `
  CategoriesWithPagination($search: String, $page: Int!, $limit: Int!) {
    categoriesWithPagination(search: $search, page: $page, limit: $limit) {
      page
      limit
      total
      data {
        _id
        title
        slug
        image
        status
      }
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLLazyQuery({
    query,
    key: 'categoriesWithPagination',
    fetchPolicy: 'cache-and-network'
  });

  return { categoriesWithPaginationTrigger: trigger, categoriesWithPaginationResult: result, categoriesWithPaginationLoading: loading, categoriesWithPaginationError: error }
}