import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  ProductUpdateById($id: String!, $category: String!, $brand: String, $title: String!, $price: Int!, $offerPrice: Int, $deliveryTime: String!, $description: String, $overview: String, $image: [String]!, $video: String, $status: Status!) {
    productUpdateById(id: $id, category: $category, brand: $brand, title: $title, price: $price, offerPrice: $offerPrice, deliveryTime: $deliveryTime, description: $description, overview: $overview, image: $image, video: $video, status: $status) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'productUpdateById'
  });

  return { productUpdateByIdTrigger: trigger, productUpdateByIdResult: result, productUpdateByIdLoading: loading, productUpdateByIdError: error };
}