import { useEffect, useState } from 'react';
import { Card, Table, Button, Avatar, Typography, Tag, Row, Col, Menu, Form } from "antd";
import { DeleteOutlined } from '@ant-design/icons';

import useCategories from 'hooks/category/useCategories';
import useFeaturedProductsByCategory from 'hooks/product/useFeaturedProductsByCategory';
import useNotFeaturedProductsByCategory from 'hooks/product/useNotFeaturedProductsByCategory';
import useFeaturedProductUpdateById from 'hooks/product/useFeaturedProductUpdateById';

import config from "configs/config";

import Loader from 'components/Loader';
import SelectInput from 'components/Input/SelectInput';

import './styles.scss';

export default ({ }) => {
  const [form] = Form.useForm();

  const [selectedCategory, setSelectedCategory] = useState('');
  const [showAddProductForm, setShowAddProductForm] = useState(false);

  const { categoriesResult, categoriesLoading } = useCategories();
  const { featuredProductsByCategoryTrigger, featuredProductsByCategoryResult, featuredProductsByCategoryLoading } = useFeaturedProductsByCategory();
  const { notFeaturedProductsByCategoryTrigger, notFeaturedProductsByCategoryResult, notFeaturedProductsByCategoryLoading } = useNotFeaturedProductsByCategory();

  const updateAllProductListTrigger = (category) => {
    if (category !== selectedCategory) {
      setSelectedCategory(category);
    }
    setShowAddProductForm(false);
    featuredProductsByCategoryTrigger({ category: category })
    notFeaturedProductsByCategoryTrigger({ category: category });
  }

  useEffect(() => {
    if (!categoriesLoading) {
      if (categoriesResult && categoriesResult.length) {
        updateAllProductListTrigger(categoriesResult[0]._id);
      }
    }
  }, [categoriesLoading, categoriesResult]);

  const formatNotFeaturedProductsByCategory = () => {
    let formattedNotFeaturedProductsByCategory = [];
    if (!notFeaturedProductsByCategoryLoading && notFeaturedProductsByCategoryResult) {
      formattedNotFeaturedProductsByCategory = notFeaturedProductsByCategoryResult.map(c => ({ title: c.title, value: c._id }));
    }

    return formattedNotFeaturedProductsByCategory;
  }

  const { featuredProductUpdateByIdTrigger, featuredProductUpdateByIdResult, featuredProductUpdateByIdLoading } = useFeaturedProductUpdateById();
  useEffect(() => {
    if (!featuredProductUpdateByIdLoading) {
      if (featuredProductUpdateByIdResult) {
        form.resetFields();
        updateAllProductListTrigger(selectedCategory);
      }
    }
  }, [featuredProductUpdateByIdLoading, featuredProductUpdateByIdResult])

  const columns = [
    {
      title: "S No",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) => <Typography.Title level={5} style={{ maxWidth: '250px', whiteSpace: 'normal' }}>{index + 1}</Typography.Title>
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, record) => <Avatar.Group>
        <Avatar
          className="shape-avatar"
          shape="square"
          size={40}
          src={`${config.assetURL}/${record.image[0]}`}
        ></Avatar>
        <div className="avatar-info" style={{ maxWidth: '450px', }}>
          <Typography.Title level={5} style={{ whiteSpace: 'normal' }}>{record.title}</Typography.Title>
        </div>
      </Avatar.Group>
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      render: (value) => <Typography.Title level={5} style={{ maxWidth: '250px', whiteSpace: 'normal' }}>{value}</Typography.Title>
    },
    {
      title: "Sku",
      dataIndex: "sku",
      key: "sku",
      render: (value) => <Typography.Title level={5}>{value}</Typography.Title>
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (_, record) => <DeleteOutlined style={{ color: '#fb1c1c' }} onClick={() => featuredProductUpdateByIdTrigger({ id: record._id, isFeatured: false })} />
    }
  ];

  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      title="Featured Products"
      extra={
        <Button type="primary" className="tag-primary" onClick={() => setShowAddProductForm(!showAddProductForm)}>
          {showAddProductForm ? 'Cancel' : 'Add New Product'}
        </Button>
      }
    >
      <div className="featuredproduct">
        <Row gutter={[16, 16]}>
          <Col md={4}>
            {categoriesLoading ?
              <Loader />
              : null}
            {categoriesResult ?
              <Menu theme="light" mode="inline" defaultSelectedKeys={categoriesResult[0]?._id} onClick={(val) => updateAllProductListTrigger(val.key)}>
                {categoriesResult.map(c =>
                  <Menu.Item key={c._id} >
                    {c.title}
                  </Menu.Item>
                )}
              </Menu>
              : null}
          </Col>
          <Col md={20}>
            {featuredProductsByCategoryLoading || notFeaturedProductsByCategoryLoading || featuredProductUpdateByIdLoading ?
              <Loader />
              : null}
            {showAddProductForm ?
              <div className='featuredproduct_createform'>
                <div className='featuredproduct_createform_title'>
                  Select a product to add
                </div>
                <div className='featuredproduct_createform_form'>
                  <Form
                    className="row-col"
                    layout="vertical"
                    form={form}
                    initialValues={{}}
                    onFinish={({ product }) => featuredProductUpdateByIdTrigger({ id: product, isFeatured: true })}
                  >
                    <Row gutter={[24, 0]}>
                      <Col md={20}>
                        <SelectInput
                          name='product'
                          placeholder='Select a Product'
                          rules={[
                            {
                              required: true,
                              message: "Please input product!",
                            }
                          ]}
                          options={formatNotFeaturedProductsByCategory()}
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Item wrapperCol={{ span: 24 }}>
                          <Button type="primary" htmlType="submit" loading={featuredProductUpdateByIdLoading} disabled={featuredProductsByCategoryLoading || notFeaturedProductsByCategoryLoading} block>
                            Add Product
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
              : null}
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={featuredProductsByCategoryResult}
                rowKey="title"
                pagination={false}
                className="ant-border-space"
              />
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
}
