import { getValueOf, STORAGE_KEYS } from 'utils/storage';

// import Register from 'pages/Auth/Register';
import Login from 'pages/Auth/Login';
import Logout from 'pages/Auth/Logout';
import Dashboard from 'pages/Dashboard';
import CategoryList from 'pages/Category/List';
import CategoryCreate from 'pages/Category/Create';
import CategoryUpdate from 'pages/Category/Update';
import BannerUpdate from 'pages/Banner/Update';
import BrandList from 'pages/Brand/List';
import BrandCreate from 'pages/Brand/Create';
import BrandUpdate from 'pages/Brand/Update';
import ProductList from 'pages/Product/List';
import ProductCreate from 'pages/Product/Create';
import ProductUpdate from 'pages/Product/Update';
import FeaturedProductList from 'pages/FeaturedProduct/List';
import BannerList from 'pages/Banner/List';
import BannerCreate from 'pages/Banner/Create';
import InquiryList from 'pages/Inquiry/List';
import ContactMessage from 'pages/ContactMessage/List';
import UserList from 'pages/User/List';
import UserCreate from 'pages/User/Create';
import UserUpdate from 'pages/User/Update';
import SettingUpdate from 'pages/Setting/Update';

const icons = {
  default: [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
        fill={"#1890ff"}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
        fill={"#1890ff"}
      ></path>
    </svg>,
  ],
  dashboard: [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={"#1890ff"}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={"#1890ff"}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={"#1890ff"}
      ></path>
    </svg>,
  ]
}

export const routeConfig = {
  dashboard: {
    type: 'private',
    path: '/dashboard',
    component: Dashboard,
    sidebar: {
      title: 'Dashboard',
      icon: icons.default
    },
  },
  category: {
    type: 'private',
    path: '/category',
    component: CategoryList,
    roles: ['DATA_ENTRY'],
    sidebar: {
      title: 'Category',
      icon: icons.default
    },
  },
  categoryCreate: {
    type: 'private',
    path: '/category/create',
    component: CategoryCreate,
    roles: ['DATA_ENTRY']
  },
  categoryUpdate: {
    type: 'private',
    path: '/category/:id/update',
    component: CategoryUpdate,
    roles: ['DATA_ENTRY']
  },
  brand: {
    type: 'private',
    path: '/brand',
    component: BrandList,
    roles: ['DATA_ENTRY'],
    sidebar: {
      title: 'Brand',
      icon: icons.default
    },
  },
  brandCreate: {
    type: 'private',
    path: '/brand/create',
    component: BrandCreate,
    roles: ['DATA_ENTRY']
  },
  brandUpdate: {
    type: 'private',
    path: '/brand/:id/update',
    component: BrandUpdate,
    roles: ['DATA_ENTRY']
  },
  product: {
    type: 'private',
    path: '/product',
    component: ProductList,
    roles: ['DATA_ENTRY'],
    sidebar: {
      title: 'Product',
      icon: icons.default
    },
  },
  productCreate: {
    type: 'private',
    path: '/product/create',
    component: ProductCreate,
    roles: ['DATA_ENTRY']
  },
  productUpdate: {
    type: 'private',
    path: '/product/:id/update',
    component: ProductUpdate,
    roles: ['DATA_ENTRY']
  },
  featuredProduct: {
    type: 'private',
    path: '/featured-product',
    component: FeaturedProductList,
    sidebar: {
      title: 'Featured Product',
      icon: icons.default
    },
  },
  banner: {
    type: 'private',
    path: '/banner',
    component: BannerList,
    roles: ['ADVERTISING'],
    sidebar: {
      title: 'Banner',
      icon: icons.default
    },
  },
  bannerCreate: {
    type: 'private',
    path: '/banner/create',
    component: BannerCreate,
    roles: ['ADVERTISING'],
  },
  bannerUpdate: {
    type: 'private',
    path: '/banner/:id/update',
    component: BannerUpdate,
    roles: ['ADVERTISING'],
  },
  inquiry: {
    type: 'private',
    path: '/inquiry',
    component: InquiryList,
    roles: ['SALES'],
    sidebar: {
      title: 'Inquiry',
      icon: icons.default
    },
  },
  contactMessage: {
    type: 'private',
    path: '/contact-message',
    component: ContactMessage,
    roles: ['SALES'],
    sidebar: {
      title: 'Contact Message',
      icon: icons.default
    },
  },
  user: {
    type: 'private',
    path: '/user',
    component: UserList,
    sidebar: {
      title: 'User',
      icon: icons.default
    },
  },
  userCreate: {
    type: 'private',
    path: '/user/create',
    component: UserCreate
  },
  userUpdate: {
    type: 'private',
    path: '/user/:id/update',
    component: UserUpdate
  },
  setting: {
    type: 'private',
    path: '/setting',
    component: SettingUpdate,
    sidebar: {
      title: 'Site Setting',
      icon: icons.default
    },
  },
  // register: {
  //   type: 'auth',
  //   path: '/register',
  //   component: Register
  // },
  login: {
    type: 'auth',
    path: '/login',
    component: Login,
    roles: ['ALL']
  },
  logout: {
    type: 'default',
    path: '/logout',
    component: Logout,
    roles: ['ALL'],
    sidebar: {
      title: 'Logout',
      icon: icons.default
    },
  },
};

export const getRoutes = ({ sidebar }) => {
  const role = getValueOf(STORAGE_KEYS.ROLE);

  let routeValues = Object.keys(routeConfig);
  if (sidebar) {
    routeValues = Object.keys(routeConfig).filter(rcf => routeConfig[rcf]['sidebar'])
  }

  const roleRoutes = routeValues.reduce((rr, rc) => {
    if (role === 'SUPERADMIN' || role === 'ADMIN' || (routeConfig[rc]['roles'] && (routeConfig[rc]['roles'].includes(role) || routeConfig[rc]['roles'].includes('ALL')))) {
      rr[rc] = routeConfig[rc];
    }

    return rr;
  }, {});

  return roleRoutes;
};