import { Route, Redirect } from 'react-router-dom';

import AuthLayout from 'layouts/AuthLayout';

import { isAuthenticated } from 'services/auth';

export default ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => {

      if (isAuthenticated()) {
        return <Redirect push to={{ pathname: '/', search: props.location.search, state: { from: props.location } }} />;
      }

      return <AuthLayout><Component {...props} {...rest} /></AuthLayout>;
    }} />
  );
}