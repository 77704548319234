import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  InquiryUpdateById($id: String!, $quantity: String, $remarks: String, $status: InquiryStatus!) {
    inquiryUpdateById(id: $id, quantity: $quantity, remarks: $remarks, status: $status) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: "inquiryUpdateById",
  });

  return {
    inquiryUpdateByIdTrigger: trigger,
    inquiryUpdateByIdResult: result,
    inquiryUpdateByIdLoading: loading,
    inquiryUpdateByIdError: error,
  };
};
