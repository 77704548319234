import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  UserAdminLogin($email: String!, $password: String!, $rememberMe: Boolean!) {
    userAdminLogin(email: $email, password: $password, rememberMe: $rememberMe) {
      user {
        _id
        firstName
        role
      }
      accessToken
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'userAdminLogin'
  });

  return { userAdminLoginTrigger: trigger, userAdminLoginResult: result, userAdminLoginLoading: loading, userAdminLoginError: error }
}