import { setValueOf, getValueOf, removeValueOf, STORAGE_KEYS } from "utils/storage";

export const authenticate = (accessToken, user, expire) => {
    const cookieAge = expire ? 24 * 60 * 60 : 365 * 24 * 60 * 60;

    setValueOf(STORAGE_KEYS.ACCESS_TOKEN, accessToken, cookieAge);
    setValueOf(STORAGE_KEYS.NAME, user.firstName, cookieAge);
    setValueOf(STORAGE_KEYS.ROLE, user.role, cookieAge);
}

export const isAuthenticated = () => {
    let isAuthenticated = false;

    const accessToken = getValueOf(STORAGE_KEYS.ACCESS_TOKEN);

    if (accessToken && accessToken !== '') {
        isAuthenticated = true;
    }

    return isAuthenticated;
}

export const unAuthenticate = () => {
    removeValueOf(STORAGE_KEYS.ACCESS_TOKEN);
    removeValueOf(STORAGE_KEYS.NAME);
    removeValueOf(STORAGE_KEYS.ROLE);
}