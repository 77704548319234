import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  BannerDeleteById($id: String!) {
    bannerDeleteById(id: $id) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'bannerDeleteById'
  });

  return { bannerDeleteByIdTrigger: trigger, bannerDeleteByIdResult: result, bannerDeleteByIdLoading: loading, bannerDeleteByIdError: error };
}