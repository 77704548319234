import useGraphQLQuery from "hooks/common/useGraphQLQuery";

const query = `
  CategoryById($id: String!) {
    categoryById(id: $id) {
      _id
      title
      image
      status
    }
  }
`;

export default (id) => {
  const { result, loading, error } = useGraphQLQuery({
    query,
    key: 'categoryById',
    variables: { id },
    fetchPolicy: 'cache-and-network'
  });

  return { categoryByIdResult: result, categoryByIdLoading: loading, categoryByIdError: error };
}