import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  BannerUpdateById($id: String!, $title: String!, $link: String, $type: BannerType!, $image: String!, $status: Status!) {
    bannerUpdateById(id: $id, title: $title, link: $link, type: $type, image: $image, status: $status) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'bannerUpdateById'
  });

  return { bannerUpdateByIdTrigger: trigger, bannerUpdateByIdResult: result, bannerUpdateByIdLoading: loading, bannerUpdateByIdError: error };
}