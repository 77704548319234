import { useEffect, useState } from "react";
import { Row, Col, Card, Form, Button, Alert } from "antd";

import useBannerById from "hooks/banner/useBannerById";
import useBannerUpdateById from "hooks/banner/useBannerUpdateById";

import { routeConfig } from "Routes/config";

import config from "configs/config";

import { fileUpload } from "services/file";

import ImageUpload from "components/Input/ImageUpload";
import TextInput from "components/Input/TextInput";
import SelectInput from "components/Input/SelectInput";
import Loader from "components/Loader";

import "./styles.scss";

export default ({ history, match }) => {
  const urlId = match.params.id;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { bannerByIdResult, bannerByIdLoading } = useBannerById(urlId);
  useEffect(() => {
    if (!bannerByIdLoading) {
      if (bannerByIdResult) {
        form.setFieldsValue({
          image: bannerByIdResult.image,
          title: bannerByIdResult.title,
          link: bannerByIdResult.link,
          type: bannerByIdResult.type,
          status: bannerByIdResult.status,
        });
      }
    }
  }, [bannerByIdLoading, bannerByIdResult]);

  const {
    bannerUpdateByIdTrigger,
    bannerUpdateByIdResult,
    bannerUpdateByIdLoading,
    bannerUpdateByIdError,
  } = useBannerUpdateById();
  const submitFormData = (formData) => {
    formData.id = urlId;

    setError("");
    setLoading(true);
    if (formData.image instanceof File) {
      fileUpload("banner", formData.image).then((res) => {
        formData.image = res.data;

        bannerUpdateByIdTrigger(formData);
      });
    } else {
      bannerUpdateByIdTrigger(formData);
    }
  };

  useEffect(() => {
    if (!bannerUpdateByIdLoading) {
      if (bannerUpdateByIdError) {
        const errorMessage =
          bannerUpdateByIdError.message || "Something went wrong.";
        setError(errorMessage);
        setLoading(false);
      }

      if (bannerUpdateByIdResult) {
        setLoading(false);
        history.push(routeConfig.banner.path);
      }
    }
  }, [bannerUpdateByIdLoading, bannerUpdateByIdResult]);

  return (
    <Card bordered={false} className="criclebox tablespace" title="Update">
      <div className="bannerupdate">
        {bannerByIdLoading ? <Loader /> : null}
        <Form
          className="row-col"
          layout="vertical"
          form={form}
          initialValues={{}}
          onFinish={submitFormData}
        >
          <Row gutter={[24, 0]}>
            <Col md={24}>
              <ImageUpload
                form={form}
                label="Image"
                name="image"
                initialPreview={
                  bannerByIdResult && bannerByIdResult.image
                    ? `${config.assetURL}/${bannerByIdResult.image}`
                    : ""
                }
                group="banner"
                rules={[
                  {
                    required: true,
                    message: "Please input image!",
                  },
                ]}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label="Title"
                name="title"
                placeholder="Title"
                rules={[
                  {
                    required: true,
                    message: "Please input title!",
                  },
                ]}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label="Link"
                name="link"
                placeholder="Link"
                rules={[]}
              />
            </Col>
            <Col md={12}>
              <SelectInput
                label="Type"
                name="type"
                placeholder="Type"
                rules={[
                  {
                    required: true,
                    message: "Please input type!",
                  },
                ]}
                options={[
                  { title: "Main", value: "MAIN" },
                  { title: "Offer", value: "OFFER" },
                  { title: "Advertisement", value: "ADVERTISEMENT" },
                ]}
              />
            </Col>
            <Col md={12}>
              <SelectInput
                label="Status"
                name="status"
                placeholder="Status"
                rules={[
                  {
                    required: true,
                    message: "Please input status!",
                  },
                ]}
                options={[
                  { title: "Active", value: "ACTIVE" },
                  { title: "In-Active", value: "INACTIVE" },
                ]}
              />
            </Col>
            {error != "" ? (
              <Col md={24}>
                <Alert
                  message={error}
                  type="error"
                  style={{ marginBottom: 24 }}
                />
              </Col>
            ) : null}
            <Col md={12}>
              <Form.Item wrapperCol={{ offset: 18, span: 6 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={bannerByIdLoading}
                  block
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Card>
  );
};
