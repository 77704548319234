import useGraphQLLazyQuery from "hooks/common/useGraphQLLazyQuery";

const query = `
  InquiriesWithPagination($page: Int!, $limit: Int!, $status: InquiryStatus, $inquirynumber: String) {
    inquiriesWithPagination(page: $page, limit: $limit, status: $status, inquirynumber: $inquirynumber) {
      page
      limit
      total
      data {
        _id
        number
        mobile
        product {
          _id
          image
          title
        }
        quantity
        remarks
        status
        createdBy {
          _id
          email
        }
        updatedBy {
          _id
          email
        }
      }
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLLazyQuery({
    query,
    key: "inquiriesWithPagination",
    fetchPolicy: "cache-and-network",
  });

  return {
    inquiriesWithPaginationTrigger: trigger,
    inquiriesWithPaginationResult: result,
    inquiriesWithPaginationLoading: loading,
    inquiriesWithPaginationError: error,
  };
};
