import { createContext, useState } from "react";

import { getValueOf, STORAGE_KEYS } from "utils/storage";

export const UserContext = createContext({ role: getValueOf(STORAGE_KEYS.ROLE) || '' });

export const UserProvider = ({ children }) => {
  const [role, setRole] = useState(getValueOf(STORAGE_KEYS.ROLE) || '');

  return (
    <UserContext.Provider value={{ role, updateRole: (role) => setRole(role) }}>
      {children}
    </UserContext.Provider>
  );
}