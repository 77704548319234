import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  BulkProductUpdate($products: [ProductInput]!) {
    bulkProductUpdate(products: $products) {
      success
      msg
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'bulkProductUpdate'
  });

  return { bulkProductUpdateTrigger: trigger, bulkProductUpdateResult: result, bulkProductUpdateLoading: loading, bulkProductUpdateError: error };
}