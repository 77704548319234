import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  FeaturedProductUpdateById($id: String!, $isFeatured: Boolean!) {
    featuredProductUpdateById(id: $id, isFeatured: $isFeatured) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'featuredProductUpdateById'
  });

  return { featuredProductUpdateByIdTrigger: trigger, featuredProductUpdateByIdResult: result, featuredProductUpdateByIdLoading: loading, featuredProductUpdateByIdError: error };
}