import { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Alert } from "antd";

import useUserCreate from 'hooks/user/useUserCreate';

import { routeConfig } from 'Routes/config';

import { fileUpload } from 'services/file';

import ImageUpload from "components/Input/ImageUpload";
import TextInput from "components/Input/TextInput";
import SelectInput from "components/Input/SelectInput";

import './styles.scss';

export default ({ history }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { userCreateTrigger, userCreateResult, userCreateLoading, userCreateError } = useUserCreate();
  const submitFormData = (formData) => {
    setError('');
    setLoading(true);

    if (formData.displayPicture && formData.displayPicture !== '') {
      fileUpload('user', formData.displayPicture).then(res => {
        formData.displayPicture = res.data;

        userCreateTrigger(formData);
      });
    } else {
      formData.displayPicture = '';
      userCreateTrigger(formData);
    }
  };

  useEffect(() => {
    if (!userCreateLoading) {
      if (userCreateError) {
        const errorMessage = userCreateError.message || 'Something went wrong.'
        setError(errorMessage);
        setLoading(false);
      }

      if (userCreateResult) {
        setLoading(false);
        history.push(routeConfig.user.path);
      }
    }
  }, [userCreateLoading, userCreateResult]);

  return (
    <Card
      bordered={false}
      className="criclebox tablespace"
      title="Create"
    >
      <div className="usercreate">
        <Form
          className="row-col"
          layout="vertical"
          form={form}
          initialValues={{}}
          onFinish={submitFormData}
        >
          <Row gutter={[24, 0]}>
            <Col md={24}>
              <ImageUpload
                form={form}
                label='Display Picture'
                name='displayPicture'
                rules={[]}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Email'
                name='email'
                placeholder='Email'
                rules={[
                  {
                    required: true,
                    message: "Please input email!",
                  }
                ]}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label='First Name'
                name='firstName'
                placeholder='First Name'
                rules={[
                  {
                    required: true,
                    message: "Please input first name!",
                  }
                ]}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label='Last Name'
                name='lastName'
                placeholder='Last Name'
                rules={[
                  {
                    required: true,
                    message: "Please input last name!",
                  }
                ]}
              />
            </Col>
            <Col md={12}>
              <SelectInput
                label='Gender'
                name='gender'
                placeholder='Gender'
                rules={[
                  {
                    required: true,
                    message: "Please input gender!",
                  }
                ]}
                options={[
                  { title: 'Male', value: 'MALE' },
                  { title: 'Female', value: 'FEMALE' },
                  { title: 'Others', value: 'OTHERS' }
                ]}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label='Password'
                name='password'
                placeholder='Password'
                rules={[
                  {
                    required: true,
                    message: "Please input last password!",
                  }
                ]}
              />
            </Col>
            <Col md={12}>
              <SelectInput
                label='Role'
                name='role'
                placeholder='Role'
                rules={[
                  {
                    required: true,
                    message: "Please input role!",
                  }
                ]}
                options={[
                  { title: 'Data Entry', value: 'DATA_ENTRY' },
                  { title: 'Sales', value: 'SALES' },
                  { title: 'Advertising', value: 'ADVERTISING' }
                ]}
              />
            </Col>
            <Col md={12}>
              <SelectInput
                label='Status'
                name='status'
                placeholder='Status'
                rules={[
                  {
                    required: true,
                    message: "Please input status!",
                  }
                ]}
                options={[
                  { title: 'Active', value: 'ACTIVE' },
                  { title: 'In-Active', value: 'INACTIVE' }
                ]}
              />
            </Col>
            {error != '' ?
              <Col md={24}>
                <Alert message={error} type='error' style={{ marginBottom: 24 }} />
              </Col>
              :
              null}
            <Col md={12}>
              <Form.Item wrapperCol={{ offset: 18, span: 6 }}>
                <Button type="primary" htmlType="submit" loading={loading} block>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Card >
  );
}
