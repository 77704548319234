import { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Alert } from "antd";

import useUserById from 'hooks/user/useUserById';
import useUserUpdateById from 'hooks/user/useUserUpdateById';

import { routeConfig } from 'Routes/config';

import config from 'configs/config';

import { fileUpload } from 'services/file';

import ImageUpload from "components/Input/ImageUpload";
import TextInput from "components/Input/TextInput";
import SelectInput from "components/Input/SelectInput";
import Loader from 'components/Loader';

import './styles.scss';

export default ({ history, match }) => {
  const urlId = match.params.id;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { userByIdResult, userByIdLoading } = useUserById(urlId);
  useEffect(() => {
    if (!userByIdLoading) {
      if (userByIdResult) {
        form.setFieldsValue({ displayPicture: userByIdResult.displayPicture, email: userByIdResult.email, firstName: userByIdResult.firstName, lastName: userByIdResult.lastName, gender: userByIdResult.gender, role: userByIdResult.role, status: userByIdResult.status });
      }
    }
  }, [userByIdLoading, userByIdResult]);

  const { userUpdateByIdTrigger, userUpdateByIdResult, userUpdateByIdLoading, userUpdateByIdError } = useUserUpdateById();
  const submitFormData = (formData) => {
    formData.id = urlId;

    setError('');
    setLoading(true);
    if (formData.displayPicture && formData.displayPicture !== '') {
      if (formData.displayPicture instanceof File) {
        fileUpload('user', formData.displayPicture).then(res => {
          formData.displayPicture = res.data;

          userUpdateByIdTrigger(formData);
        });
      } else {
        userUpdateByIdTrigger(formData);
      }
    } else {
      formData.displayPicture = '';
      userUpdateByIdTrigger(formData);
    }
  };

  useEffect(() => {
    if (!userUpdateByIdLoading) {
      if (userUpdateByIdError) {
        const errorMessage = userUpdateByIdError.message || 'Something went wrong.'
        setError(errorMessage);
        setLoading(false);
      }

      if (userUpdateByIdResult) {
        setLoading(false);
        history.push(routeConfig.user.path);
      }
    }
  }, [userUpdateByIdLoading, userUpdateByIdResult]);

  return (
    <Card
      bordered={false}
      className="criclebox tablespace"
      title="Update"
    >
      <div className="userupdate">
        {userByIdLoading ?
          <Loader />
          : null}
        <Form
          className="row-col"
          layout="vertical"
          form={form}
          initialValues={{}}
          onFinish={submitFormData}
        >
          <Row gutter={[24, 0]}>
            <Col md={24}>
              <ImageUpload
                form={form}
                label='Display Picture'
                name='displayPicture'
                initialPreview={userByIdResult && userByIdResult.displayPicture ? `${config.assetURL}/${userByIdResult.displayPicture}` : ''}
                rules={[]}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Email'
                name='email'
                placeholder='Email'
                rules={[
                  {
                    required: true,
                    message: "Please input email!",
                  }
                ]}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label='First Name'
                name='firstName'
                placeholder='First Name'
                rules={[
                  {
                    required: true,
                    message: "Please input first name!",
                  }
                ]}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label='Last Name'
                name='lastName'
                placeholder='Last Name'
                rules={[
                  {
                    required: true,
                    message: "Please input last name!",
                  }
                ]}
              />
            </Col>
            <Col md={12}>
              <SelectInput
                label='Gender'
                name='gender'
                placeholder='Gender'
                rules={[
                  {
                    required: true,
                    message: "Please input gender!",
                  }
                ]}
                options={[
                  { title: 'Male', value: 'MALE' },
                  { title: 'Female', value: 'FEMALE' },
                  { title: 'Others', value: 'OTHERS' }
                ]}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label='Password (If required to change)'
                name='password'
                placeholder='Password'
                rules={[]}
              />
            </Col>
            <Col md={12}>
              <SelectInput
                label='Role'
                name='role'
                placeholder='Role'
                rules={[
                  {
                    required: true,
                    message: "Please input role!",
                  }
                ]}
                options={[
                  { title: 'Data Entry', value: 'DATA_ENTRY' },
                  { title: 'Sales', value: 'SALES' },
                  { title: 'Advertising', value: 'ADVERTISING' }
                ]}
              />
            </Col>
            <Col md={12}>
              <SelectInput
                label='Status'
                name='status'
                placeholder='Status'
                rules={[
                  {
                    required: true,
                    message: "Please input status!",
                  }
                ]}
                options={[
                  { title: 'Active', value: 'ACTIVE' },
                  { title: 'In-Active', value: 'INACTIVE' }
                ]}
              />
            </Col>
            {error != '' ?
              <Col md={24}>
                <Alert message={error} type='error' style={{ marginBottom: 24 }} />
              </Col>
              :
              null}
            <Col md={12}>
              <Form.Item wrapperCol={{ offset: 18, span: 6 }}>
                <Button type="primary" htmlType="submit" loading={loading} disabled={userByIdLoading} block>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Card>
  );
}
