import { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Alert } from "antd";

import useActiveSetting from 'hooks/setting/useActiveSetting';
import useSettingUpdate from 'hooks/setting/useSettingUpdate';

import config from 'configs/config';

import { fileUpload } from 'services/file';

import ImageUpload from "components/Input/ImageUpload";
import TextInput from "components/Input/TextInput";
import Loader from 'components/Loader';

import './styles.scss';

export default ({ }) => {
  const [form] = Form.useForm();

  const [apiStatus, setApiStatus] = useState({ loading: false, success: false, error: '' });

  const { activeSettingResult, activeSettingLoading } = useActiveSetting();
  useEffect(() => {
    if (!activeSettingLoading) {
      if (activeSettingResult) {
        form.setFieldsValue({ logo: activeSettingResult.logo, address: activeSettingResult.address, primaryPhone: activeSettingResult.primaryPhone, secondaryPhone: activeSettingResult.secondaryPhone, officePhone: activeSettingResult.officePhone, infoEmail: activeSettingResult.infoEmail, officeTime: activeSettingResult.officeTime, facebookLink: activeSettingResult.facebookLink, instagramLink: activeSettingResult.instagramLink, youtubeLink: activeSettingResult.youtubeLink });
      }
    }
  }, [activeSettingLoading, activeSettingResult]);

  const { settingUpdateTrigger, settingUpdateResult, settingUpdateLoading, settingUpdateError } = useSettingUpdate();
  const submitFormData = (formData) => {
    setApiStatus({ loading: true, success: false, error: '' })
    if (formData.logo instanceof File) {
      fileUpload('logo', formData.logo).then(res => {
        formData.logo = res.data;

        settingUpdateTrigger(formData);
      });
    } else {
      settingUpdateTrigger(formData);
    }
  };

  useEffect(() => {
    if (!settingUpdateLoading) {
      if (settingUpdateError) {
        const errorMessage = settingUpdateError.message || 'Something went wrong.'
        setApiStatus({ loading: false, success: false, error: errorMessage })
      }

      if (settingUpdateResult) {
        setApiStatus({ loading: false, success: true, error: '' });
      }
    }
  }, [settingUpdateLoading, settingUpdateResult]);

  return (
    <Card
      bordered={false}
      className="criclebox tablespace"
      title="Update"
    >
      <div className="categoryupdate">
        {activeSettingLoading ?
          <Loader />
          : null}
        <Form
          className="row-col"
          layout="vertical"
          form={form}
          initialValues={{}}
          onFinish={submitFormData}
        >
          <Row gutter={[24, 0]}>
            <Col md={24}>
              <ImageUpload
                form={form}
                label='Logo'
                name='logo'
                initialPreview={activeSettingResult && activeSettingResult.logo ? `${config.assetURL}/${activeSettingResult.logo}` : ''}
                rules={[
                  {
                    required: true,
                    message: "Please input logo!",
                  }
                ]}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Address'
                name='address'
                placeholder='Address'
                rules={[
                  {
                    required: true,
                    message: "Please input address!",
                  }
                ]}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label='Primary Phone'
                name='primaryPhone'
                placeholder='Primary Phone'
                rules={[
                  {
                    required: true,
                    message: "Please input primary phone!",
                  },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Please input number!"
                  }
                ]}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label='Secondary Phone'
                name='secondaryPhone'
                placeholder='Secondary Phone'
                rules={[
                  {
                    required: true,
                    message: "Please input secondary phone!"
                  },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Please input number!"
                  }
                ]}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Office Phone'
                name='officePhone'
                placeholder='Office Phone'
                rules={[]}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Info Email'
                name='infoEmail'
                placeholder='Info Email'
                rules={[
                  {
                    required: true,
                    message: "Please input info email!"
                  }
                ]}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Office Time'
                name='officeTime'
                placeholder='Office Time'
                rules={[
                  {
                    required: true,
                    message: "Please input office time!",
                  }
                ]}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Facebook Link'
                name='facebookLink'
                placeholder='facebookLink'
                rules={[]}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Instagram Link'
                name='instagramLink'
                placeholder='instagramLink'
                rules={[]}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Youtube Link'
                name='youtubeLink'
                placeholder='youtubeLink'
                rules={[]}
              />
            </Col>
            {apiStatus.error != '' ?
              <Col md={24}>
                <Alert message={apiStatus.error} type='error' style={{ marginBottom: 24 }} />
              </Col>
              :
              null}
            {apiStatus.success != '' ?
              <Col md={24}>
                <Alert message={'Setting Updated Successfully'} type='success' style={{ marginBottom: 24 }} />
              </Col>
              :
              null}
            <Col md={12}>
              <Form.Item wrapperCol={{ offset: 18, span: 6 }}>
                <Button type="primary" htmlType="submit" loading={apiStatus.loading} disabled={activeSettingLoading} block>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Card>
  );
}
