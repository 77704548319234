import useGraphQLLazyQuery from 'hooks/common/useGraphQLLazyQuery';

const query = `
  ProductsWithPagination($search: String, $sku: String, $category: String, $page: Int!, $limit: Int!) {
    productsWithPagination(search: $search, sku: $sku, category: $category, page: $page, limit: $limit) {
      page
      limit
      total
      data {
        _id
        category {
          _id
          title
        }
        brand {
          _id
          title
        }
        title
        slug
        sku
        price
        offerPrice
        deliveryTime
        description
        overview
        image
        video
        status
      }
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLLazyQuery({
    query,
    key: 'productsWithPagination',
    fetchPolicy: 'cache-and-network'
  });

  return { productsWithPaginationTrigger: trigger, productsWithPaginationResult: result, productsWithPaginationLoading: loading, productsWithPaginationError: error }
}