import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  ProductDeleteById($id: String!) {
    productDeleteById(id: $id) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'productDeleteById'
  });

  return { productDeleteByIdTrigger: trigger, productDeleteByIdResult: result, productDeleteByIdLoading: loading, productDeleteByIdError: error };
}