import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Table, Button, Avatar, Typography, Tag, Form, Row, Col, Pagination } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import useBannersWithPagination from 'hooks/banner/useBannersWithPagination';
import useBannerDeleteById from 'hooks/banner/useBannerDeleteById';

import config from "configs/config";

import { routeConfig } from 'Routes/config';

import { extractValueFromQueryString } from 'utils/util';

import TextInput from 'components/Input/TextInput';
import Loader from 'components/Loader';

import './styles.scss';

export default ({ history, location }) => {
  let search = extractValueFromQueryString(location.search, 'search')
  if (!search) {
    search = ''
  }
  let page = extractValueFromQueryString(location.search, 'page')
  if (!page) {
    page = 1
  }
  const limit = 10

  const [form] = Form.useForm();

  const [filterParams, setFilterParams] = useState({});

  const { bannersWithPaginationTrigger, bannersWithPaginationResult, bannersWithPaginationLoading } = useBannersWithPagination();

  useEffect(() => {
    let params = {}

    if (location.search) {
      const queryStringObject = extractValueFromQueryString(location.search)

      if (queryStringObject) {
        form.setFieldsValue(queryStringObject)
        setFilterParams(queryStringObject)

        Object.keys(queryStringObject).forEach(qso => {
          params[qso] = queryStringObject[qso]
        })
      }
    }

    //pagination update
    params.page = parseInt(page, 10)
    params.limit = limit

    bannersWithPaginationTrigger(params);
  }, [page, location.search]);

  const applyFilter = () => {
    const filterParamsArray = Object.keys(filterParams).map(fp => `${fp}=${fp === 'page' ? '' : filterParams[fp]}`)

    if (filterParamsArray.length) {

      history.push(`?${filterParamsArray.join('&')}`)
    }
  }

  const clearFilter = () => {
    const filterParamsArray = Object.keys(filterParams).map(fp => `${fp}=`)

    if (filterParamsArray.length) {

      history.push(`${routeConfig.banner.path}?${filterParamsArray.join('&')}`)
    }
  }

  const changePage = (page) => {
    let filterParams = {};

    const queryStringObject = extractValueFromQueryString(location.search)

    if (queryStringObject && Object.keys(queryStringObject).length) {
      Object.keys(queryStringObject).forEach(fpo => {
        if (fpo !== 'page' && fpo !== 'limit') {
          filterParams[fpo] = queryStringObject[fpo]
        }
      })
    }

    const filterParamsArray = Object.keys(filterParams).map(fp => `${fp}=${Array.isArray(filterParams[fp]) ? filterParams[fp].join(',') : filterParams[fp]}`)

    if (filterParamsArray.length) {

      history.push(`?${filterParamsArray.join('&')}&page=${page}`)
    } else {
      history.push(`?page=${page}`)
    }

  }

  const { bannerDeleteByIdTrigger, bannerDeleteByIdLoading } = useBannerDeleteById();

  const columns = [
    {
      title: "S No",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) => <Typography.Title level={5} style={{ maxWidth: '250px', whiteSpace: 'normal' }}>{((parseInt(page) - 1) * 10) + index + 1}</Typography.Title>
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, record) => <Avatar.Group>
        <Avatar
          className="shape-avatar"
          shape="square"
          size={40}
          src={`${config.assetURL}/${record.image}`}
        ></Avatar>
        <div className="avatar-info">
          <Typography.Title level={5}>{record.title}</Typography.Title>
        </div>
      </Avatar.Group>
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (value) => <Typography.Title level={5}>{value}</Typography.Title>
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (value) => <Typography.Title level={5}>{value}</Typography.Title>
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (value) => <Tag color={value.toLowerCase() === 'active' ? 'green' : 'red'} >{value.toLowerCase()}</Tag>
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (_, record) => <><Link to={routeConfig.bannerUpdate.path.replace(':id', record._id)}><EditOutlined style={{ color: '#1890ff', marginRight: '5px' }} /></Link> <DeleteOutlined style={{ color: '#fb1c1c' }} onClick={() => bannerDeleteByIdTrigger({ id: record._id })} /></>
    }
  ];

  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      title="Banners"
      extra={
        <Link to={routeConfig.bannerCreate.path}>
          <Button type="primary" className="tag-primary">
            Create
          </Button>
        </Link>
      }
    >
      <div className="banner">
        <div className='banner_options'>
          <Row gutter={[16, 16]}>
            <Col md={6}>
              <Form
                className="row-col"
                layout="vertical"
                form={form}
                initialValues={{}}
                onValuesChange={(values) => setFilterParams({ ...filterParams, ...values })}
              >
                <TextInput
                  name='search'
                  placeholder='Search'
                  rules={[]}
                />
              </Form>
            </Col>
            <Col md={3}>
              <Button type="primary" className="tag-default" onClick={applyFilter} block>
                Search
              </Button>
            </Col>
            <Col md={3}>
              <Button type="default" className="tag-default" onClick={clearFilter} block>
                Clear
              </Button>
            </Col>
          </Row>
        </div>
        {bannersWithPaginationLoading || bannerDeleteByIdLoading ?
          <Loader />
          :
          null}
        <div className="table-responsive">
          <Table
            columns={columns}
            dataSource={bannersWithPaginationResult?.data}
            rowKey="title"
            pagination={false}
            className="ant-border-space"
          />
          {bannersWithPaginationResult ?
            <div className='table-pagination'>
              <Pagination
                current={parseInt(page)}
                pageSize={limit}
                total={bannersWithPaginationResult.total}
                showSizeChanger={false}
                onChange={page => changePage(page)}
              />
            </div>
            :
            null
          }
        </div>
      </div>
    </Card>
  );
}
