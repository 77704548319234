import { useEffect, useState } from "react";
import { Row, Col, Card, Form, Button, Alert } from "antd";

import useBannerCreate from "hooks/banner/useBannerCreate";

import { routeConfig } from "Routes/config";

import { fileUpload } from "services/file";

import ImageUpload from "components/Input/ImageUpload";
import TextInput from "components/Input/TextInput";
import SelectInput from "components/Input/SelectInput";

import "./styles.scss";

export default ({ history }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const {
    bannerCreateTrigger,
    bannerCreateResult,
    bannerCreateLoading,
    bannerCreateError,
  } = useBannerCreate();
  const submitFormData = (formData) => {
    setError("");
    setLoading(true);
    fileUpload("banner", formData.image).then((res) => {
      formData.image = res.data;

      bannerCreateTrigger(formData);
    });
  };

  useEffect(() => {
    if (!bannerCreateLoading) {
      if (bannerCreateError) {
        const errorMessage =
          bannerCreateError.message || "Something went wrong.";
        setError(errorMessage);
        setLoading(false);
      }

      if (bannerCreateResult) {
        setLoading(false);
        history.push(routeConfig.banner.path);
      }
    }
  }, [bannerCreateLoading, bannerCreateResult]);

  return (
    <Card bordered={false} className="criclebox tablespace" title="Create">
      <div className="bannercreate">
        <Form
          className="row-col"
          layout="vertical"
          form={form}
          initialValues={{}}
          onFinish={submitFormData}
        >
          <Row gutter={[24, 0]}>
            <Col md={24}>
              <ImageUpload
                form={form}
                label="Image"
                name="image"
                group="banner"
                rules={[
                  {
                    required: true,
                    message: "Please input image!",
                  },
                ]}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label="Title"
                name="title"
                placeholder="Title"
                rules={[
                  {
                    required: true,
                    message: "Please input title!",
                  },
                ]}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label="Link"
                name="link"
                placeholder="Link"
                rules={[]}
              />
            </Col>
            <Col md={12}>
              <SelectInput
                label="Type"
                name="type"
                placeholder="Type"
                rules={[
                  {
                    required: true,
                    message: "Please input type!",
                  },
                ]}
                options={[
                  { title: "Main", value: "MAIN" },
                  { title: "Offer", value: "OFFER" },
                  { title: "Advertisement", value: "ADVERTISEMENT" },
                ]}
              />
            </Col>
            <Col md={12}>
              <SelectInput
                label="Status"
                name="status"
                placeholder="Status"
                rules={[
                  {
                    required: true,
                    message: "Please input status!",
                  },
                ]}
                options={[
                  { title: "Active", value: "ACTIVE" },
                  { title: "In-Active", value: "INACTIVE" },
                ]}
              />
            </Col>
            {error != "" ? (
              <Col md={24}>
                <Alert
                  message={error}
                  type="error"
                  style={{ marginBottom: 24 }}
                />
              </Col>
            ) : null}
            <Col md={12}>
              <Form.Item wrapperCol={{ offset: 18, span: 6 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  block
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Card>
  );
};
