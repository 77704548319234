import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  BrandDeleteById($id: String!) {
    brandDeleteById(id: $id) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'brandDeleteById'
  });

  return { brandDeleteByIdTrigger: trigger, brandDeleteByIdResult: result, brandDeleteByIdLoading: loading, brandDeleteByIdError: error };
}