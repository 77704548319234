import useGraphQLLazyQuery from 'hooks/common/useGraphQLLazyQuery';

const query = `
  BrandsWithPagination($search: String, $page: Int!, $limit: Int!) {
    brandsWithPagination(search: $search, page: $page, limit: $limit) {
      page
      limit
      total
      data {
        _id
        title
        slug
        image
        status
      }
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLLazyQuery({
    query,
    key: 'brandsWithPagination',
    fetchPolicy: 'cache-and-network'
  });

  return { brandsWithPaginationTrigger: trigger, brandsWithPaginationResult: result, brandsWithPaginationLoading: loading, brandsWithPaginationError: error }
}