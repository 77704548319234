import { Form, Select } from 'antd';

import './styles.scss';

export default ({ label, name, placeholder, options = [], defaultValue, rules }) => {
  return (
    <Form.Item
      className="username selectinput"
      label={label}
      name={name}
      rules={rules}
    >
      <Select
        defaultValue={defaultValue}
        placeholder={placeholder}
      >
        {options.map((o, i) =>
          <Select.Option key={i} value={o.value}>{o.title}</Select.Option>
        )}
      </Select>
    </Form.Item>
  );
}