import { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Alert } from "antd";

import useBrandCreate from 'hooks/brand/useBrandCreate';

import { routeConfig } from 'Routes/config';

import { fileUpload } from 'services/file';

import ImageUpload from "components/Input/ImageUpload";
import TextInput from "components/Input/TextInput";
import SelectInput from "components/Input/SelectInput";

import './styles.scss';

export default ({ history }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { brandCreateTrigger, brandCreateResult, brandCreateLoading, brandCreateError } = useBrandCreate();
  const submitFormData = (formData) => {
    setError('');
    setLoading(true);
    fileUpload('brand', formData.image).then(res => {
      formData.image = res.data;

      brandCreateTrigger(formData);
    });
  };

  useEffect(() => {
    if (!brandCreateLoading) {
      if (brandCreateError) {
        const errorMessage = brandCreateError.message || 'Something went wrong.'
        setError(errorMessage);
        setLoading(false);
      }

      if (brandCreateResult) {
        setLoading(false);
        history.push(routeConfig.brand.path);
      }
    }
  }, [brandCreateLoading, brandCreateResult]);

  return (
    <Card
      bordered={false}
      className="criclebox tablespace"
      title="Create"
    >
      <div className="brandcreate">
        <Form
          className="row-col"
          layout="vertical"
          form={form}
          initialValues={{}}
          onFinish={submitFormData}
        >
          <Row gutter={[24, 0]}>
            <Col md={24}>
              <ImageUpload
                form={form}
                label='Image'
                name='image'
                rules={[
                  {
                    required: true,
                    message: "Please input image!",
                  }
                ]}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Title'
                name='title'
                placeholder='Title'
                rules={[
                  {
                    required: true,
                    message: "Please input title!",
                  }
                ]}
              />
            </Col>
            <Col md={24}>
              <SelectInput
                label='Status'
                name='status'
                placeholder='Status'
                rules={[
                  {
                    required: true,
                    message: "Please input status!",
                  }
                ]}
                options={[
                  { title: 'Active', value: 'ACTIVE' },
                  { title: 'In-Active', value: 'INACTIVE' }
                ]}
              />
            </Col>
            {error != '' ?
              <Col md={24}>
                <Alert message={error} type='error' style={{ marginBottom: 24 }} />
              </Col>
              :
              null}
            <Col md={12}>
              <Form.Item wrapperCol={{ offset: 18, span: 6 }}>
                <Button type="primary" htmlType="submit" loading={loading} block>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Card >
  );
}
