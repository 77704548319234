import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  SettingUpdate($logo: String!, $address: String!, $primaryPhone: String!, $secondaryPhone: String!, $officePhone: String, $infoEmail: String!, $officeTime: String!, $facebookLink: String, $instagramLink: String, $youtubeLink: String) {
    settingUpdate(logo: $logo, address: $address, primaryPhone: $primaryPhone, secondaryPhone: $secondaryPhone, officePhone: $officePhone, infoEmail: $infoEmail, officeTime: $officeTime, facebookLink: $facebookLink, instagramLink: $instagramLink, youtubeLink: $youtubeLink) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'settingUpdate'
  });

  return { settingUpdateTrigger: trigger, settingUpdateResult: result, settingUpdateLoading: loading, settingUpdateError: error };
}