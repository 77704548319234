import { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Alert } from "antd";

import useCategories from 'hooks/category/useCategories';
import useBrands from 'hooks/brand/useBrands';
import useProductById from 'hooks/product/useProductById';
import useProductUpdateById from 'hooks/product/useProductUpdateById';

import { routeConfig } from 'Routes/config';

import config from 'configs/config';

import { fileUpload } from 'services/file';

import ImageUpload from "components/Input/ImageUpload";
import TextInput from "components/Input/TextInput";
import SelectInput from "components/Input/SelectInput";
import EditorInput from 'components/Input/EditorInput';
import Loader from 'components/Loader';

import './styles.scss';

export default ({ history, match }) => {
  const urlId = match.params.id;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { categoriesResult, categoriesLoading } = useCategories();
  const formatCategoryOptions = () => {
    let formattedCategories = [];
    if (!categoriesLoading && categoriesResult) {
      formattedCategories = categoriesResult.map(c => ({ title: c.title, value: c._id }));
    }
    return formattedCategories;
  }

  const { brandsResult, brandsLoading } = useBrands();
  const formatBrandOptions = () => {
    let formattedBrands = [];
    if (!brandsLoading && brandsResult) {
      formattedBrands = brandsResult.map(c => ({ title: c.title, value: c._id }));
    }

    return [{ title: 'No Brand', value: null }, ...formattedBrands];
  }

  const { productByIdResult, productByIdLoading } = useProductById(urlId);
  const [multipleImage, setMultipleImage] = useState([]);
  useEffect(() => {
    if (!productByIdLoading) {
      if (productByIdResult) {
        form.setFieldsValue({ image: productByIdResult.image[productByIdResult.image.length - 1], video: productByIdResult.video, category: productByIdResult.category?._id, brand: productByIdResult.brand?._id, title: productByIdResult.title, price: productByIdResult.price, offerPrice: productByIdResult.offerPrice, deliveryTime: productByIdResult.deliveryTime, description: productByIdResult.description, overview: productByIdResult.overview, status: productByIdResult.status });
        setMultipleImage(productByIdResult.image);
      }
    }
  }, [productByIdLoading, productByIdResult]);

  const { productUpdateByIdTrigger, productUpdateByIdResult, productUpdateByIdLoading, productUpdateByIdError } = useProductUpdateById();
  const submitFormData = (formData) => {
    formData.id = urlId;

    setError('');
    setLoading(true);

    //formatting price values to interger
    formData.price = parseInt(formData.price);
    if (formData.offerPrice && formData.offerPrice !== '') {
      formData.offerPrice = parseInt(formData.offerPrice);
    } else {
      delete formData.offerPrice;
    }

    const multipleImageWithFiles = [];
    const multipleImageWithoutFiles = [];
    multipleImage.forEach(mi => {
      if (mi instanceof File) {
        multipleImageWithFiles.push(mi);
      } else {
        multipleImageWithoutFiles.push(mi);
      }
    });

    if (multipleImageWithFiles && multipleImageWithFiles.length) {
      fileUpload('product', multipleImageWithFiles).then(res => {
        const response = Array.isArray(res.data) ? res.data : [res.data];

        formData.image = [...multipleImageWithoutFiles, ...response];

        productUpdateByIdTrigger(formData);
      });
    } else {
      formData.image = multipleImageWithoutFiles;

      productUpdateByIdTrigger(formData);
    }
  };

  useEffect(() => {
    if (!productUpdateByIdLoading) {
      if (productUpdateByIdError) {
        const errorMessage = productUpdateByIdError.message || 'Something went wrong.'
        setError(errorMessage);
        setLoading(false);
      }

      if (productUpdateByIdResult) {
        setLoading(false);
        history.push(routeConfig.product.path);
      }
    }
  }, [productUpdateByIdLoading, productUpdateByIdResult]);

  return (
    <Card
      bordered={false}
      className="criclebox tablespace"
      title="Update"
    >
      <div className="categoryupdate">
        {productByIdLoading ?
          <Loader />
          : null}
        <Form
          className="row-col"
          layout="vertical"
          form={form}
          initialValues={{}}
          onFinish={submitFormData}
        >
          <Row gutter={[24, 0]}>
            <Col md={24}>
              <ImageUpload
                form={form}
                label='Image'
                name='image'
                initialPreview={productByIdResult && productByIdResult.image ? productByIdResult.image.map(p => `${config.assetURL}/${p}`) : []}
                multiple
                multipleImage={multipleImage}
                setMultipleImage={setMultipleImage}
                rules={[
                  {
                    required: true,
                    message: "Please input image!",
                  }
                ]}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Video (Youtube URL)'
                name='video'
                placeholder='Video (Youtube URL)'
                rules={[]}
              />
            </Col>
            <Col md={12}>
              <SelectInput
                label='Category'
                name='category'
                placeholder='Category'
                rules={[
                  {
                    required: true,
                    message: "Please input category!",
                  }
                ]}
                options={formatCategoryOptions()}
              />
            </Col>
            <Col md={12}>
              <SelectInput
                label='Brand'
                name='brand'
                placeholder='Brand'
                rules={[]}
                options={formatBrandOptions()}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Title'
                name='title'
                placeholder='Title'
                rules={[
                  {
                    required: true,
                    message: "Please input title!",
                  }
                ]}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label='Price'
                name='price'
                placeholder='Price'
                rules={[
                  {
                    required: true,
                    message: "Please input price!"
                  },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Please input number!"
                  }
                ]}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label='Offer Price'
                name='offerPrice'
                placeholder='Offer Price'
                rules={[
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Please input number!"
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || parseInt(getFieldValue('price')) > parseInt(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Offer Price must be less than price!'));
                    },
                  })
                ]}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Delivery Time'
                name='deliveryTime'
                placeholder='Delivery Time'
                rules={[
                  {
                    required: true,
                    message: "Please input delivery time!",
                  }
                ]}
              />
            </Col>
            <Col md={24}>
              <EditorInput
                form={form}
                label='Description'
                name='description'
                rules={[]}
              />
            </Col>
            <Col md={24}>
              <EditorInput
                form={form}
                label='Overview'
                name='overview'
                rules={[]}
              />
            </Col>
            <Col md={24}>
              <SelectInput
                label='Status'
                name='status'
                placeholder='Status'
                rules={[
                  {
                    required: true,
                    message: "Please input status!",
                  }
                ]}
                options={[
                  { title: 'Active', value: 'ACTIVE' },
                  { title: 'In-Active', value: 'INACTIVE' }
                ]}
              />
            </Col>
            {error != '' ?
              <Col md={24}>
                <Alert message={error} type='error' style={{ marginBottom: 24 }} />
              </Col>
              :
              null}
            <Col md={12}>
              <Form.Item wrapperCol={{ offset: 18, span: 6 }}>
                <Button type="primary" htmlType="submit" loading={loading} disabled={productByIdLoading} block>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Card>
  );
}
