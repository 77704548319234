import useGraphQLLazyQuery from 'hooks/common/useGraphQLLazyQuery';

const query = `
  ContactMessagesWithPagination($page: Int!, $limit: Int!, $status: ContactMessageStatus) {
    contactMessagesWithPagination(page: $page, limit: $limit, status: $status) {
      page
      limit
      total
      data {
        _id
        name
        mobile
        message
        status
        createdBy {
          _id
          email
        }
        updatedBy {
          _id
          email
        }
      }
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLLazyQuery({
    query,
    key: 'contactMessagesWithPagination',
    fetchPolicy: 'cache-and-network'
  });

  return { contactMessagesWithPaginationTrigger: trigger, contactMessagesWithPaginationResult: result, contactMessagesWithPaginationLoading: loading, contactMessagesWithPaginationError: error }
}