import useGraphQLQuery from 'hooks/common/useGraphQLQuery';

const query = `
  Brands {
    brands {
      _id
      title
      slug
      image
      status
    }
  }
`;

export default () => {
  const { result, loading, error } = useGraphQLQuery({
    query,
    key: 'brands',
    fetchPolicy: 'cache-and-network'
  });

  return { brandsResult: result, brandsLoading: loading, brandsError: error }
}