import { ApolloProvider } from '@apollo/client';

import client from 'configs/graphqlClient';

import { UserProvider } from 'context/UserContext';

import Routes from 'Routes';

import "antd/dist/antd.css";
import "assets/styles/main.css";
import "assets/styles/responsive.css";

export default ({ }) => {
  return (
    <ApolloProvider client={client}>
      <UserProvider>
        <Routes />
      </UserProvider>
    </ApolloProvider>
  );
}