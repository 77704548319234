import useGraphQLLazyQuery from 'hooks/common/useGraphQLLazyQuery';

const query = `
  BannersWithPagination($search: String, $page: Int!, $limit: Int!) {
    bannersWithPagination(search: $search, page: $page, limit: $limit) {
      page
      limit
      total
      data {
        _id
        title
        link
        type
        image
        status
      }
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLLazyQuery({
    query,
    key: 'bannersWithPagination',
    fetchPolicy: 'cache-and-network'
  });

  return { bannersWithPaginationTrigger: trigger, bannersWithPaginationResult: result, bannersWithPaginationLoading: loading, bannersWithPaginationError: error }
}