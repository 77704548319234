import { useState, useEffect, useContext } from 'react';
// import { Link } from 'react-router-dom';
import { Button, Row, Col, Form, Typography, Alert } from "antd";

import { UserContext } from 'context/UserContext';

import useUserAdminLogin from 'hooks/user/useUserAdminLogin';

import { authenticate } from 'services/auth';

import TextInput from 'components/Input/TextInput';
import ToggleInput from 'components/Input/ToggleInput';

export default ({ history }) => {
  const { updateRole } = useContext(UserContext);

  const [form] = Form.useForm();

  const [error, setError] = useState('');

  const { userAdminLoginTrigger, userAdminLoginResult, userAdminLoginLoading, userAdminLoginError } = useUserAdminLogin();

  const submitFormData = (formData) => {
    userAdminLoginTrigger(formData);
  }

  useEffect(() => {
    if (!userAdminLoginLoading) {
      if (userAdminLoginError) {
        const errorMessage = userAdminLoginError.message || 'Something went wrong.'
        setError(errorMessage)
      }

      if (userAdminLoginResult && userAdminLoginResult.accessToken) {
        const { accessToken, user } = userAdminLoginResult;

        const rememberMe = form.getFieldValue('rememberMe');

        //store accesstoken
        const expireToken = !rememberMe;
        authenticate(accessToken, user, expireToken);

        //update role in context
        updateRole(user.role);

        history.push('/');
      }
    }
  }, [userAdminLoginLoading, userAdminLoginResult]);

  return (
    <Row gutter={[24, 0]} justify="space-around" style={{ marginBottom: '30px' }}>
      <Col
        xs={{ span: 24, offset: 0 }}
        lg={{ span: 6, offset: 2 }}
        md={{ span: 12 }}
      >
        <Typography.Title className="mb-15">Log In</Typography.Title>
        <Typography.Title className="font-regular text-muted" level={5}>
          Enter your email and password to sign in
        </Typography.Title>

        <Form
          className="row-col"
          layout="vertical"
          form={form}
          initialValues={{ rememberMe: true }}
          onFinish={submitFormData}
        >
          <TextInput
            label='Email'
            name='email'
            placeholder='Email'
            rules={[
              {
                required: true,
                message: "Please input your email!",
              }
            ]}
          />

          <TextInput
            label='Password'
            type='password'
            name='password'
            placeholder='Password'
            rules={[
              {
                required: true,
                message: "Please input your password!",
              }
            ]}
          />

          <ToggleInput
            form={form}
            label='Remember Me'
            name='rememberMe'
            defaultChecked
          />

          {error !== '' ?
            <Form.Item>
              <Alert message={error} type='error' />
            </Form.Item>
            :
            null
          }

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={userAdminLoginLoading}
            >
              LOG IN
            </Button>
          </Form.Item>

          {/* <div className="font-semibold text-muted">
            Don't have an account?{" "}
            <Link to={routeConfig.register.path} className="text-dark font-bold">
              Register
            </Link>
          </div> */}
        </Form>
      </Col>
    </Row>
  );
}
