import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  BrandUpdateById($id: String!, $title: String!, $image: String!, $status: Status!) {
    brandUpdateById(id: $id, title: $title, image: $image, status: $status) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'brandUpdateById'
  });

  return { brandUpdateByIdTrigger: trigger, brandUpdateByIdResult: result, brandUpdateByIdLoading: loading, brandUpdateByIdError: error };
}