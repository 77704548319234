import useGraphQLQuery from "hooks/common/useGraphQLQuery";

const query = `
  ProductById($id: String!) {
    productById(id: $id) {
      _id
      category {
        _id
      }
      brand {
        _id
      }
      title
      sku
      price
      offerPrice
      deliveryTime
      description
      overview
      image
      video
      status
    }
  }
`;

export default (id) => {
  const { result, loading, error } = useGraphQLQuery({
    query,
    key: 'productById',
    variables: { id },
    fetchPolicy: 'cache-and-network'
  });

  return { productByIdResult: result, productByIdLoading: loading, productByIdError: error };
}