import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  UserDeleteById($id: String!) {
    userDeleteById(id: $id) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'userDeleteById'
  });

  return { userDeleteByIdTrigger: trigger, userDeleteByIdResult: result, userDeleteByIdLoading: loading, userDeleteByIdError: error };
}