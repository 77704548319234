import { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Alert } from "antd";

import useCategories from 'hooks/category/useCategories';
import useBrands from 'hooks/brand/useBrands';
import useProductCreate from 'hooks/product/useProductCreate';

import { routeConfig } from 'Routes/config';

import { fileUpload } from 'services/file';

import ImageUpload from "components/Input/ImageUpload";
import TextInput from "components/Input/TextInput";
import SelectInput from "components/Input/SelectInput";
import EditorInput from 'components/Input/EditorInput';

import './styles.scss';

export default ({ history }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { categoriesResult, categoriesLoading } = useCategories();
  const formatCategoryOptions = () => {
    let formattedCategories = [];
    if (!categoriesLoading && categoriesResult) {
      formattedCategories = categoriesResult.map(c => ({ title: c.title, value: c._id }));
    }

    return formattedCategories;
  }

  const { brandsResult, brandsLoading } = useBrands();
  const formatBrandOptions = () => {
    let formattedBrands = [];
    if (!brandsLoading && brandsResult) {
      formattedBrands = brandsResult.map(c => ({ title: c.title, value: c._id }));
    }

    return [{ title: 'No Brand', value: null }, ...formattedBrands];
  }

  const { productCreateTrigger, productCreateResult, productCreateLoading, productCreateError } = useProductCreate();
  const [multipleImage, setMultipleImage] = useState([]);
  const submitFormData = (formData) => {
    setError('');
    setLoading(true);
    fileUpload('product', multipleImage).then(res => {
      formData.image = Array.isArray(res.data) ? res.data : [res.data];

      //formatting price values to interger
      formData.price = parseInt(formData.price);
      if (formData.offerPrice && formData.offerPrice !== '') {
        formData.offerPrice = parseInt(formData.offerPrice);
      } else {
        delete formData.offerPrice;
      }

      productCreateTrigger(formData);
    });
  };

  useEffect(() => {
    if (!productCreateLoading) {
      if (productCreateError) {
        const errorMessage = productCreateError.message || 'Something went wrong.'
        setError(errorMessage);
        setLoading(false);
      }

      if (productCreateResult) {
        setLoading(false);
        history.push(routeConfig.product.path);
      }
    }
  }, [productCreateLoading, productCreateResult]);

  return (
    <Card
      bordered={false}
      className="criclebox tablespace"
      title="Create"
    >
      <div className="productcreate">
        <Form
          className="row-col"
          layout="vertical"
          form={form}
          initialValues={{}}
          onFinish={submitFormData}
        >
          <Row gutter={[24, 0]}>
            <Col md={24}>
              <ImageUpload
                form={form}
                label='Image'
                name='image'
                multiple
                multipleImage={multipleImage}
                setMultipleImage={setMultipleImage}
                rules={[
                  {
                    required: true,
                    message: "Please input image!",
                  }
                ]}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Video (Youtube URL)'
                name='video'
                placeholder='Video (Youtube URL)'
                rules={[]}
              />
            </Col>
            <Col md={12}>
              <SelectInput
                label='Category'
                name='category'
                placeholder='Category'
                rules={[
                  {
                    required: true,
                    message: "Please input category!",
                  }
                ]}
                options={formatCategoryOptions()}
              />
            </Col>
            <Col md={12}>
              <SelectInput
                label='Brand'
                name='brand'
                placeholder='Brand'
                rules={[]}
                options={formatBrandOptions()}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Title'
                name='title'
                placeholder='Title'
                rules={[
                  {
                    required: true,
                    message: "Please input title!",
                  }
                ]}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label='Price'
                name='price'
                placeholder='Price'
                rules={[
                  {
                    required: true,
                    message: "Please input price!",
                  },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Please input number!"
                  }
                ]}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label='Offer Price'
                name='offerPrice'
                placeholder='Offer Price'
                rules={[
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Please input number!"
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || parseInt(getFieldValue('price')) > parseInt(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Offer Price must be less than price!'));
                    },
                  })
                ]}
              />
            </Col>
            <Col md={24}>
              <TextInput
                label='Delivery Time'
                name='deliveryTime'
                placeholder='Delivery Time'
                rules={[
                  {
                    required: true,
                    message: "Please input delivery time!",
                  }
                ]}
              />
            </Col>
            <Col md={24}>
              <EditorInput
                form={form}
                label='Description'
                name='description'
                rules={[]}
              />
            </Col>
            <Col md={24}>
              <EditorInput
                form={form}
                label='Overview'
                name='overview'
                rules={[]}
              />
            </Col>
            <Col md={24}>
              <SelectInput
                label='Status'
                name='status'
                placeholder='Status'
                rules={[
                  {
                    required: true,
                    message: "Please input status!",
                  }
                ]}
                options={[
                  { title: 'Active', value: 'ACTIVE' },
                  { title: 'In-Active', value: 'INACTIVE' }
                ]}
              />
            </Col>
            {error != '' ?
              <Col md={24}>
                <Alert message={error} type='error' style={{ marginBottom: 24 }} />
              </Col>
              :
              null}
            <Col md={12}>
              <Form.Item wrapperCol={{ offset: 18, span: 6 }}>
                <Button type="primary" htmlType="submit" loading={loading} block>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Card >
  );
}
