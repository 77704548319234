import useGraphQLMutation from "hooks/common/useGraphQLMutation";

const query = `
  ContactMessageUpdateById($id: String!, $status: ContactMessageStatus!) {
    contactMessageUpdateById(id: $id, status: $status) {
      _id
    }
  }
`;

export default () => {
  const { trigger, result, loading, error } = useGraphQLMutation({
    query,
    key: 'contactMessageUpdateById'
  });

  return { contactMessageUpdateByIdTrigger: trigger, contactMessageUpdateByIdResult: result, contactMessageUpdateByIdLoading: loading, contactMessageUpdateByIdError: error };
}